import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors } from '@mui/material';
import { FdTooltip, FdSkeleton } from '@fifthdomain/fe-shared';

const ProgressIndicator = ({
  variant,
  range1,
  range2,
  marker,
  tooltipText,
  loading,
}) => {
  const { grey } = colors;
  const baseColor = colors[variant];
  const rangeColor1 = variant === 'grey' ? baseColor[400] : baseColor[100];
  const rangeColor2 = variant === 'grey' ? baseColor[500] : baseColor[200];
  const Wrapper = tooltipText ? FdTooltip : React.Fragment;

  return (
    <FdSkeleton loading={loading} variant="text">
      <Wrapper title={tooltipText}>
        <Box
          width="100%"
          height="16px"
          position="relative"
          my={2}
          justifyContent="center"
        >
          <Box
            style={{
              backgroundColor: grey[300],
              borderRadius: '4px',
            }}
            width="100%"
            height="100%"
          />
          <Box
            style={{
              backgroundColor: rangeColor1,
              borderRadius: '4px',
              position: 'absolute',
              top: 0,
              left: `${range1?.start}%`,
              width: `${range1?.end - range1?.start}%`,
              height: '100%',
              borderLeft: '1px solid rgba(255, 255, 255, 1)',
              borderRight: '1px solid rgba(255, 255, 255, 1)',
            }}
          />
          <Box
            style={{
              backgroundColor: rangeColor2,
              borderRadius: '4px',
              position: 'absolute',
              top: 0,
              left: `${range2?.start}%`,
              width: `${range2?.end - range2?.start}%`,
              height: '100%',
            }}
          />
          <Box
            style={{
              backgroundColor: '#4D4B4B',
              borderRadius: '2px',
              position: 'absolute',
              top: 0,
              left: `${marker}%`,
              width: '5px',
              height: '100%',
            }}
          />
        </Box>
      </Wrapper>
    </FdSkeleton>
  );
};
ProgressIndicator.propTypes = {
  variant: PropTypes.oneOf(['red', 'orange', 'green', 'grey']).isRequired,
  range1: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  range2: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  marker: PropTypes.number.isRequired,
  tooltipText: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProgressIndicator;
