import React from 'react';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FdIconWithTooltip, FdTypography } from '@fifthdomain/fe-shared';
import { ASSESSMENT_TYPES } from '../../constants';

const AssessmentType = () => {
  const { getValues } = useFormContext();
  return (
    <Box>
      <FdTypography variant="subtitle1">Assessment Type</FdTypography>
      <Box className="flex items-center mb-3">
        <FdTypography variant="body1" color="secondary">
          {getValues('teamBased')
            ? ASSESSMENT_TYPES.TEAM_BASED
            : ASSESSMENT_TYPES.INDIVIDUAL_BASED}
        </FdTypography>
        <FdIconWithTooltip title="All assessments on the FifthDomain platform are individual-based." />
      </Box>
    </Box>
  );
};

export default AssessmentType;
