import React, { useState } from 'react';
import { Box, Grid, Switch, IconButton } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import _ from 'lodash';
import {
  useParams,
  useLocation,
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  differenceInMilliseconds,
  formatDuration,
  isAfter,
  hoursToMinutes,
  minutesToHours,
  addHours,
  addMinutes,
  differenceInMinutes,
} from 'date-fns';
import {
  BasePage,
  FdTab,
  FdCard,
  FdButton,
  FdModal,
  FdTypography as Typography,
  FdChip,
  FdTable,
  FdAlert,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTooltip,
  FdSkeleton,
  Authorization,
  useRecentLinks,
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { useQuery, gql, useMutation } from '@apollo/client';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import ViewTaskDrawer from '../components/Assessment/ViewTaskDrawer';
import {
  addUsers,
  createTaskAssessment,
  updateAssessment,
  deleteTaskAssessment,
  removeUserFromAssessment,
  removeInvitedUser,
  createModulePart,
  deleteModulePart,
  createHintReveal,
} from '../graphql/mutations';
import {
  InviteUsers,
  Details,
  Availability,
  Duration,
  PreMessage,
  PostMessage,
  Summary,
  AssessmentOverview,
  Jumpbox,
} from '../components/Assessment';
import TasksTable from '../components/Assessment/TasksTable';
import {
  getTasksByAssessmentId as getAssessment,
  listTaskAttemptsByAssessmentId,
  listHintReveals,
  listCourseUsersByOrgId,
  listSkills,
  listAssessmentsByOrg,
} from '../queries/customQueries';
import {
  getAssessmentStatus,
  getTotal,
  getDeletingTasks,
  getNewTasks,
  getReleasedTemplatesChallenges,
} from '../shared/utils/taskUtils';
import {
  listTasksByOrgId,
  listTaskOrgs,
  listUsersByAssessmentId,
  listInvitedUsersByAssessmentId,
  getSystemTime,
  listUsersDataByOrgId,
  listGroupsByAssessmenId,
  queryTemplatesByStatus,
  listTaskOpenedsByAssessmentId,
  listTaskAttemptAggregatesByAssessmentId,
} from '../graphql/queries';
import Insights from './Insights';
import Overview from './Overview';
import scrollToTop from '../shared/utils/scroll';
import {
  getParticipantStatusColor,
  getAssessmentStatusColor,
} from '../shared/utils/getStatusColor';
import { getParticipantStatus } from '../shared/utils/getParticipantStatus';
import {
  formatMinutes,
  getDifferenceInMinutes,
  getDateTimeZoneFormatted,
} from '../shared/utils/dateUtils';
import TeamsTab from '../components/Assessment/TeamsTab';
import useOrgId from '../hooks/useOrgId';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';
import { initialValues, validationSchema } from '../validation-schemas';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import AssessmentType from '../components/Assessment/AssessmentType';

const drawerWidth = 400;

const ViewAssessment = () => {
  const { assessmentId } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const userOrgIdData = useOrgId();
  const globalSnap = useSnapshot(globalStore);
  const tabindex = new URLSearchParams(search).get('tabindex') || 3; // defaults to details tab
  const [editDetails, setEditDetails] = useState(false);
  const [editAvailability, setEditAvailability] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [editPreAssessment, setEditPreAssessment] = useState(false);
  const [editPostAssessment, setEditPostAssessment] = useState(false);
  const [editJumpbox, setEditJumpbox] = useState(false);
  const [editTasks, setEditTasks] = useState(false);
  const [inviteUsers, setInviteUsers] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [deleteParticipant, setDeleteParticipant] = useState(false);
  const [deletedParticipantId, setDeletedParticipantId] = useState('');
  const [deletedInvitedParticipantId, setDeletedInvitedParticipantId] =
    useState('');
  const [custIndex, setCustIndex] = useState(tabindex);
  const [defaultInsight, setDefaultInsight] = useState('');
  const [maxInviteesError, setMaxInviteesError] = useState('');
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    control,
    watch,
    getValues,
    reset,
    trigger,
    setValue,
    formState: { isDirty },
  } = reactHookFormMethods;
  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };
  const [updateAssessmentMutation, { loading: updateLoading }] = useMutation(
    gql(updateAssessment),
  );
  const [createModulePartMutation] = useMutation(gql(createModulePart));
  const [deleteModulePartMutation] = useMutation(gql(deleteModulePart));
  const [createTaskAssessmentMutation] = useMutation(gql(createTaskAssessment));
  const [deleteTaskAssessmentMutation] = useMutation(gql(deleteTaskAssessment));

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listTaskAttemptsByAssessmentId), {
      variables: {
        assessmentId,
        limit: 1000,
      },
    });
  const { data: listSkillsData } = useQueryRecursive(gql(listSkills));

  const {
    data: listTaskAttemptsAggregateData,
    loading: listTaskAttemptsAggregateDataLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });

  const { data: tasksOpenedData, loading: tasksOpenedLoading } =
    useQueryRecursive(gql(listTaskOpenedsByAssessmentId), {
      variables: {
        assessmentId,
        limit: 1000,
      },
    });

  const { data: listOrgUsersData, loading: listOrgUsersLoading } =
    useQuery(gql(listUsersDataByOrgId), {
      variables: {
        orgId: userOrgIdData?.userData?.getUserById?.items[0].orgId,
      },
    });

  const { data: listHintRevealsData, refetch: refetchListHintReveals } =
    useQueryRecursive(gql(listHintReveals));

  const {
    data: listInvitedUsersByAssessmentIdData,
    refetch: refetchListInvitedUsersByAssessmentId,
  } = useQueryRecursive(gql(listInvitedUsersByAssessmentId), {
    variables: {
      assessmentId,
    },
  });

  const { data: assessmentData, loading: assessmentDataLoading } = useQuery(
    gql(getAssessment),
    {
      variables: {
        id: assessmentId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (!isDirty) {
          // if form is in changed state then do not refresh the whole form,
          // instead each block actions reset values based on action
          const { hours, minutes, endDateTime } = data?.getAssessmen || {};
          const defaultlabExpiryDateTime = addHours(
            addMinutes(new Date(endDateTime), minutes),
            hours,
          );
          reset({
            name: data.getAssessment?.name,
            description: data.getAssessment?.description,
            startDateTime: data.getAssessment?.startDateTime,
            endDateTime,
            hours,
            minutes,
            preMessage: data.getAssessment?.preMessage,
            postMessage: data.getAssessment?.postMessage,
            videoUrl: data.getAssessment?.videoUrl,
            enableVPN: data.getAssessment?.enableVPN === 'TRUE',
            jumpbox: data.getAssessment?.enableJumpbox === 'TRUE',
            taskIds: data.getAssessment?.tasks.items.map((task) => task.taskId),
            labControls: data.getAssessment.tasks.items
              ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
              .reduce((acc, curr) => {
                const {
                  duration,
                  expiry,
                  inactivityExpiry,
                  initialLabCount,
                  minLabCount,
                } = curr?.modulePart || {
                  duration: hoursToMinutes(Number(hours)) + Number(minutes),
                  expiry: defaultlabExpiryDateTime,
                  inactivityExpiry: 14,
                  initialLabCount: 0,
                  minLabCount: 0,
                };
                return {
                  ...acc,
                  [curr.taskId]: {
                    labControlDefined: true,
                    labDuration: duration,
                    labExpiryDateTime: expiry,
                    labAbandonment: inactivityExpiry,
                    initialLabPool: initialLabCount,
                    minimumLabPool: minLabCount,
                  },
                };
              }, {}),
            taskLabs: data.getAssessment.tasks.items
              ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
              .reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.taskId]: curr.modulePartId,
                };
              }, {}),
          });
          // add recent link
          addRecentLink({
            id: assessmentId,
            name: data.getAssessment?.name,
            type: 'ASSESSMENT',
            url: pathname + search,
            role: 'MANAGE',
          });
        }
      },
    },
  );

  const { data: allTasksData, loading: allTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: userOrgIdData?.userData?.getUserById?.items[0].orgId,
        filter: {
          status: { eq: 'APPROVED' },
        },
      },
      limit: 1000,
      skip: !userOrgIdData,
      onCompleted: () => scrollToTop(),
    },
  );
  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableToOrgLoading,
    refetch: allTasksAvailableRefetch,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: userOrgIdData?.userData?.getUserById?.items[0].orgId,
    },
    skip: !userOrgIdData,
  });

  const { data: serverTime } = useQuery(gql(getSystemTime));

  const {
    data: listUsersData,
    loading: listUsersLoading,
    refetch: refetchListUsersByAssessmentId,
  } = useQueryRecursive(gql(listUsersByAssessmentId), {
    variables: {
      userAssessmentAssessmentId: assessmentId,
      filter: { status: { ne: 'REMOVED' } },
      limit: 100000,
    },
  });

  const { data: listAllFinishedAssessmentsData } = useQueryRecursive(
    gql(listAssessmentsByOrg),
    {
      variables: {
        orgId: globalSnap.orgId,
        limit: 500,
      },
    },
  );

  const { data: listCourseUsersByOrgIdData } = useQueryRecursive(
    gql(listCourseUsersByOrgId),
    {
      variables: {
        orgId: globalSnap?.orgId,
      },
    },
  );

  const { data: groupsAssessmentData, loading: groupsAssessmentDataLoading } =
    useQueryRecursive(gql(listGroupsByAssessmenId), {
      variables: {
        assessmentId,
      },
    });

  const { data: releasedTemplates } = useQueryRecursive(
    gql(queryTemplatesByStatus),
    {
      variables: {
        status: 'RELEASED',
      },
    },
  );

  // Reveal Hints to the Participants
  const [createHintRevealMutation] = useMutation(gql(createHintReveal), {
    onCompleted: (_data) => {
      refetchListHintReveals();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  const hideModalAndRefetchQueries = () => {
    setInviteUsers(false);
    setRouteIndex(2);
    refetchListInvitedUsersByAssessmentId();
    refetchListUsersByAssessmentId();
  };

  const [addUsersMutation, { loading: createParticipantLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        hideModalAndRefetchQueries();
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = graphQLErrors[0]?.message;
        if (errorMessage.includes('allocated quota')) {
          setMaxInviteesError(errorMessage);
        }
        hideModalAndRefetchQueries();
      },
    },
  );

  const [removeParticipantMutation, { loading: removeParticipantLoading }] =
    useMutation(gql(removeUserFromAssessment), {
      variables: {
        userAssessmentId: deletedParticipantId,
      },
      onCompleted: (_data) => {
        refetchListUsersByAssessmentId();
        setDeleteParticipant(false);
      },
    });

  const [removeInvitedUserMutation, { loading: removeInvitedUserLoading }] =
    useMutation(gql(removeInvitedUser), {
      variables: {
        inviteToken: deletedInvitedParticipantId,
      },
      onCompleted: (_data) => {
        refetchListInvitedUsersByAssessmentId();
        setRouteIndex(2);
        setDeleteParticipant(false);
      },
    });

  // update participant data with attempts
  const allAttemptFiltered = (userId) => {
    const attempts =
      listTaskAttemptsData?.listTaskAttemptsByAssessmentId?.items?.filter(
        (listTaskAttemptTask) =>
          listTaskAttemptTask.userId === userId &&
          listTaskAttemptTask.assessmentId === assessmentId,
      );
    return attempts;
  };

  const listCourseUsersByOrgIdItems =
    listCourseUsersByOrgIdData?.listCourseUsersByOrgId?.items || [];

  const coursesAsFinishedAssessments = listCourseUsersByOrgIdItems.reduce(
    (acc, cur) => {
      const parentRecord = acc.find((record) => record.id === cur.courseId);
      if (parentRecord) {
        parentRecord.users.items.push({
          id: cur.user?.id,
          user: { email: cur.user?.email, type: cur.user?.type },
        });
      } else {
        acc.push({
          id: cur.courseId,
          name: cur.course?.name || '-',
          participantEventType: 'COURSE',
          status: 'FINISHED',
          users: {
            items: [
              {
                id: cur.user?.id,
                user: { email: cur.user?.email, type: cur.user?.type },
              },
            ],
          },
        });
      }
      return acc;
    },
    [],
  );

  const listOrgUsers = listOrgUsersData?.listUsersDataByOrgId || [];
  const allFinishedAssessments = [
    ...(listAllFinishedAssessmentsData?.listAssessmentsByOrg?.items || []),
    ...(coursesAsFinishedAssessments || []),
  ];

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const invitedParticipant =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items
      .filter((invitedUser) => !invitedUser.accepted)
      .map((participant) => ({
        id: participant.id,
        attempted: '-',
        duration: '-',
        name: '-',
        started: '-',
        taskSolved: '-',
        points: '-',
        participantType: participant.participantType
          ? upperCaseFirstLetter(participant.participantType)
          : '',
        email: participant.email,
        status: 'INVITED',
      }));

  const {
    teamBased = false,
    startDateTime,
    endDateTime,
    hours,
    minutes,
    orgId,
  } = assessmentData?.getAssessment ?? {};
  const status = getAssessmentStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );
  const statusColor = getAssessmentStatusColor(status);
  const tasksOpened =
    tasksOpenedData?.listTaskOpenedsByAssessmentId?.items || [];

  const participantRows = listUsersData?.listUsersByAssessmentId?.items?.map(
    (userAssessment) => {
      const getStatus = (userStatus, assessmentStatus) => {
        if (userStatus === 'NOT_STARTED') return 'NOT STARTED';
        if (userStatus === 'STARTED' && assessmentStatus === 'Ended')
          return 'NOT COMPLETED';
        return userStatus;
      };

      const assessmentDuration =
        hoursToMinutes(userAssessment?.assessment?.hours) +
        Number(userAssessment?.assessment?.minutes);

      const participantDuration = getDifferenceInMinutes(
        userAssessment.finishedOn
          ? new Date(userAssessment.finishedOn)
          : new Date(serverTime?.getSystemTime),
        userAssessment.startedOn
          ? new Date(userAssessment.startedOn)
          : new Date(serverTime?.getSystemTime),
      );
      return {
        id: userAssessment.id,
        userId: userAssessment.userId,
        name: userAssessment?.user?.name,
        email: userAssessment?.user?.email,
        participantType: upperCaseFirstLetter(
          userAssessment?.user?.participantType,
        ),
        status: getStatus(userAssessment.status, status),

        started: userAssessment.startedOn
          ? getDateTimeZoneFormatted(userAssessment.startedOn, true)
          : '-',
        duration: isAfter(participantDuration, assessmentDuration)
          ? assessmentDuration
          : participantDuration,
        startedOn: userAssessment.startedOn,
        hours: isAfter(participantDuration, assessmentDuration)
          ? userAssessment?.assessment?.hours
          : minutesToHours(participantDuration),

        minutes: isAfter(participantDuration, assessmentDuration)
          ? userAssessment?.assessment?.minutes
          : participantDuration % 60,
      };
    },
  );

  const filteredParticipantRows = participantRows?.map((participantRow) => {
    if (listTaskAttemptsData) {
      const attempts = allAttemptFiltered(participantRow.userId);

      const tasksAttemptSuccess = attempts?.filter(
        (attempt) => attempt.success,
      );

      const uniqueSuccessTaskSet = [
        ...new Set(
          tasksAttemptSuccess?.map(
            (taskAttemptSuccess) => taskAttemptSuccess.task,
          ),
        ),
      ];

      const taskSolved = uniqueSuccessTaskSet.length;

      // getTotal adds up the total of array of object
      const points = getTotal(uniqueSuccessTaskSet, 'recommendedPoints') || 0;
      return {
        ...participantRow,
        duration:
          participantRow?.duration !== 0 && participantRow.status === 'FINISHED'
            ? formatMinutes(participantRow.duration)
            : '-',
        taskSolved,
        points,
        attempted: attempts?.length !== 0,
        successRate:
          attempts?.length > 0
            ? Math.round((taskSolved / attempts?.length) * 100)
            : 0,
      };
    }
    return participantRow;
  });

  const assessmentTasks = assessmentData?.getAssessment?.tasks?.items.map(
    (t) => ({
      ...t.task,
      difficultyInteger: t?.task?.difficulty,
      difficultyLabel: getDifficultyLabel(t?.task?.difficulty),
      specialtyName: t?.task?.specialty?.name,
      skills: t?.task?.skills?.items.map((s) => s.skill?.name),
      techniqueTags: t?.task?.skills?.items
        .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
        .flat(),
      technologyTags: t?.task?.technologyTags?.items.map(
        (tt) => tt.technologyTag?.name,
      ),
      taskAssessmentId: t?.id,
      taskId: t?.taskId,
      creator: t?.task?.user?.name,
      ownerOrg: t?.task?.org?.name,
      ownerOrgId: t?.task?.org?.id,
      creatorOrg: t?.task?.user?.org?.name,
      creatorOrgId: t?.task?.user?.org?.id,
      specialtyAreas: [
        ...new Set(
          t.task.competencies.items
            .map((competencyData) => competencyData?.area?.areaName)
            .flat(),
        ),
      ],
      tags:
        t?.tags?.items?.filter((t1) => t1?.Tag?.orgId === globalSnap?.orgId) ||
        [],
    }),
  );

  const allTasksMadeAvailable =
    allTasksAvailableToOrg?.listTaskOrgs?.items?.map(
      (taskOrg) => taskOrg?.task,
    );

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items,
        );

  const tableData = _.uniqBy(
    [
      ...(allTasksData?.listTasksByOrgId?.items?.map((t) => ({
        ...t,
        owned: true,
      })) || []),
      ...(allTasksMadeAvailable?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
      ...(assessmentData?.getAssessment?.tasks?.items?.map((t) => ({
        ...t.task,
        taskId: t.taskId,
        taskAssessmentId: t.id,
      })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const allTasks = tableData?.map((task) => ({
    ...task,
    difficultyInteger: task?.difficulty,
    difficultyLabel: getDifficultyLabel(task?.difficulty),
    specialtyName: task?.specialty?.name,
    skills: task?.skills?.items.map((s) => s.skill?.name),
    techniqueTags: task?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: task?.technologyTags?.items.map(
      (t) => t.technologyTag?.name,
    ),
    specialtyAreas: [
      ...new Set(
        task.competencies?.items
          ?.map((competencyData) => competencyData?.area?.areaName)
          .flat(),
      ),
    ],
    creator: task?.user?.name,
    ownerOrg: task?.org?.name,
    ownerOrgId: task?.org?.id,
    creatorOrg: task?.user?.org?.name,
    creatorOrgId: task?.user?.org?.id,
    labId: task.labId,
    lab: task.lab,
    tags:
      task?.tags?.items?.filter((t1) => t1?.Tag?.orgId === globalSnap?.orgId) ||
      [],
  }));

  const taskLabs = watch('taskLabs');
  const watchTasks = watch('taskIds');

  const showEditButton = () => {
    if (status === 'Ended' || !hasManagePermission) return false;

    if (status === 'Not Started') return true;

    const hasParticipantStartedOrFinished = participantRows?.some(
      (participant) =>
        participant.status === 'STARTED' || participant.status === 'FINISHED',
    );

    if (status === 'In Progress' && !hasParticipantStartedOrFinished)
      return true;

    return false;
  };

  const milliSecondsToFinish =
    status === 'In Progress'
      ? differenceInMilliseconds(
          new Date(endDateTime),
          new Date(serverTime?.getSystemTime),
        )
      : 0;
  const specialtySkillsData = listSkillsData?.listSkills?.items || [];

  const columns = [
    { field: 'name', flex: 1, headerName: 'Name' },
    { field: 'email', flex: 1, headerName: 'Email' },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      valueGetter: (params) => getParticipantStatus(params?.value),
      renderCell: (params) => (
        <FdChip
          color={getParticipantStatusColor(params?.row?.status)}
          size="small"
          label={getParticipantStatus(params?.row?.status)}
        />
      ),
    },
    { field: 'started', flex: 1, headerName: 'Started' },
    {
      field: 'duration',
      flex: 1,
      headerName: 'Duration',
    },
    {
      field: 'taskSolved',
      flex: 1,
      headerName: 'Challenge Solved',
      valueGetter: (params) => params?.row?.taskSolved,
      renderCell: (params) => {
        return (
          <FdSkeleton loading={listTaskAttemptsLoading} height="24px">
            {params?.row?.taskSolved}
          </FdSkeleton>
        );
      },
    },
    {
      field: 'points',
      flex: 1,
      headerName: 'Points',
      valueGetter: (params) => params?.row?.points,
      renderCell: (params) => {
        return (
          <FdSkeleton loading={listTaskAttemptsLoading} height="24px">
            {params?.row?.points}
          </FdSkeleton>
        );
      },
    },
    {
      field: 'successRate',
      flex: 1,
      type: 'number',
      headerName: 'Success Rate',
      valueFormatter: (params) =>
        typeof params.value !== 'undefined' ? `${params.value}%` : '',
      renderCell: (params) => {
        return (
          <FdSkeleton loading={listTaskAttemptsLoading} height="24px">
            {typeof params.value !== 'undefined' ? `${params.value}%` : ''}
          </FdSkeleton>
        );
      },
    },
  ];
  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        if (rowData.status === 'STARTED') {
          return '';
        }

        return rowData.status !== 'FINISHED' ? (
          <FdButton
            variant="tertiary"
            size="small"
            disabled={!hasManagePermission}
            onClick={() => {
              if (rowData.status === 'INVITED') {
                setDeleteParticipant(true);
                setDeletedInvitedParticipantId(rowData.id);
              }
              if (rowData.status === 'NOT STARTED') {
                setDeleteParticipant(true);
                setDeletedParticipantId(rowData.id);
              }
            }}
          >
            DELETE
          </FdButton>
        ) : rowData?.attempted ? (
          <FdButton
            variant="tertiary"
            size="small"
            onClick={() => {
              // redirect to insights tab of a particular  clicked participant.
              setRouteIndex(1);
              setDefaultInsight(rowData?.name);
            }}
          >
            View
          </FdButton>
        ) : (
          <FdTooltip title="Due to no challenge attempts by this user, their insights report is inaccessible">
            <IconButton
              size="small"
              style={{
                marginLeft: '16px',
              }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </FdTooltip>
        );
      },
    },
  ];

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = (editTasks ? allTasks : assessmentTasks)?.find(
          (t) => t.id === id,
        );
        setActiveTask(activeTaskData);
      },
    },
  ];

  const participantFinished =
    listUsersData?.listUsersByAssessmentId?.items?.filter(
      (userAssessment) => userAssessment?.status === 'FINISHED',
    ).length;

  const participantInvitedCount =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items?.filter(
      (participant) => !participant.accepted,
    ).length;

  const participantNotRemovedCount =
    listUsersData?.listUsersByAssessmentId?.items?.length;

  const invitedCount = participantInvitedCount + participantNotRemovedCount;

  const timeSpentDataForAssessment = teamBased
    ? groupsAssessmentData?.listGroupsByAssessmenId?.items.map((ga) => ({
        userId: ga.group?.id,
        duration:
          ga.finishedOn && ga.startedOn
            ? differenceInMinutes(
                new Date(ga.finishedOn),
                new Date(ga.startedOn),
              )
            : 0,
      }))
    : participantRows?.map((p) => ({
        userId: p.userId,
        duration: p.duration,
      })) || [];

  const deleteTask = async (_id, modulePartId, task) => {
    return new Promise((resolve, reject) => {
      deleteTaskAssessmentMutation({
        variables: {
          input: {
            id: _id,
          },
        },
        onCompleted: () => {
          if (task.type === 'LAB') {
            deleteModulePartMutation({
              variables: {
                input: {
                  id: modulePartId,
                },
              },
            });
          }
          resolve();
        },
        onError: () => reject(),
      });
    });
  };
  const updateTask = async (_taskIdValue, _assessmentId, task) => {
    return new Promise((resolve, reject) => {
      if (task?.type === 'LAB') {
        createModulePartMutation({
          variables: {
            input: {
              courseModuleId: _taskIdValue,
              description: task.description,
              name: task.name,
              orderNumber: (Object.entries(taskLabs)?.length ?? 0) + 1,
              type: 'LAB',
              labId: task.labId,
            },
          },
          onCompleted: (_data) => {
            const { id, courseModuleId } = _data.createModulePart;
            setValue('taskLabs', {
              ...taskLabs,
              [courseModuleId]: id,
            });

            createTaskAssessmentMutation({
              variables: {
                input: {
                  taskId: _taskIdValue,
                  assessmentId: _assessmentId,
                  modulePartId: id,
                },
              },
              onCompleted: () => resolve(),
              onError: () => reject(),
            });
          },
          onError: (error) => {
            errorToastMessage(error.message);
          },
        });
      } else {
        createTaskAssessmentMutation({
          variables: {
            input: {
              taskId: _taskIdValue,
              assessmentId: _assessmentId,
            },
          },
          onCompleted: () => {
            resolve();
          },
          onError: () => reject(),
        });
      }
    });
  };

  const onUpdateTasks = async () => {
    const taskIdValues = getValues('taskIds')?.map((taskId) => ({ taskId }));
    if (taskIdValues.length === 0) {
      return;
    }
    const existingAssessmentTasks = assessmentData?.getAssessment?.tasks?.items;
    const deletePromises = [];
    const updatePromises = [];
    // delete existing tasks that are about to be deleted
    getDeletingTasks(existingAssessmentTasks, taskIdValues)?.forEach(
      (taskAssessment) =>
        deletePromises.push(
          deleteTask(
            taskAssessment.id,
            taskAssessment?.modulePartId,
            taskAssessment.task,
          ),
        ),
    );
    const deleteResult = await Promise.allSettled(deletePromises);
    if (deleteResult.some((p) => p.status === 'rejected')) {
      return;
    }

    // insert new tasks as per form changes
    getNewTasks(existingAssessmentTasks, taskIdValues)?.forEach((ta) => {
      const task = allTasks?.find((t) => t.id === ta.taskId);

      updatePromises.push(updateTask(ta.taskId, assessmentId, task));
    });
    const updateResult = await Promise.allSettled(updatePromises);

    if (updateResult.some((p) => p?.status === 'rejected')) {
      setEditTasks(false);
      errorToastMessage('Update failed');
      return;
    }

    if (updateResult.every((p) => p?.status === 'fulfilled')) {
      setEditTasks(false);
      successToastMessage('Success! Assessment updated!');
    }
  };

  const participants = () => (
    <Box>
      <Summary
        data={[
          {
            value: (
              <Box mb={1}>
                <FdChip color={statusColor} size="small" label={status} />
              </Box>
            ),
            description: 'Assessment Status',
          },
          ...(status === 'In Progress' && endDateTime
            ? [
                {
                  value: (
                    <Box mb={1}>
                      <FdSystemDateCountdown endDateTime={endDateTime} />
                    </Box>
                  ),
                  description: 'Time remaining',
                },
              ]
            : []),
          {
            value: <Box mb={1}>{invitedCount}</Box>,
            description: 'Participants Invited',
          },
          {
            value: <Box mb={1}>{participantFinished}</Box>,
            description: 'Participants Assessed',
          },
          {
            value: (
              <Box mb={1}>
                {participantFinished
                  ? `${Math.ceil((participantFinished / invitedCount) * 100)}%`
                  : '-'}
              </Box>
            ),
            description: 'Completion Rate',
          },
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box
        mt={2}
        mb={2}
        height="725px"
        width="100%"
        style={{ backgroundColor: 'white' }}
      >
        <FdTable
          toolbarSettings={{
            title: 'Participants',
            headerActions: hasManagePermission
              ? [
                  {
                    label: 'Invite',
                    onClick: () => {
                      if (status === 'Ended') {
                        errorToastMessage(
                          'You cannot invite Participants to an assessment that has ended',
                        );
                      } else {
                        setInviteUsers(true);
                      }
                    },
                  },
                ]
              : [],
            searchBox: true,
          }}
          actions={actions}
          rows={[
            ...(filteredParticipantRows || []),
            ...(invitedParticipant || []),
          ]}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          loading={listUsersLoading}
          gridId="assessor-admin-assessment-participants"
        />
        {inviteUsers && (
          <InviteUsers
            title="Invite Participants"
            users={inviteUsers}
            usersCallBack={async (newUserEmails, showToast = true) => {
              if (!newUserEmails) {
                setInviteUsers(false);
                setMaxInviteesError('');
                if (showToast) {
                  warningToastMessage('User(s) not invited');
                }
                return;
              }
              // add users individually
              await Promise.all(
                newUserEmails?.map((user) =>
                  addUsersMutation({
                    variables: {
                      emails: [user],
                      orgId: globalSnap?.orgId,
                      type: 'PARTICIPANT',
                      assessmentId,
                      participantEventType: 'ASSESSMENT',
                    },
                  }),
                ),
              );
              successToastMessage('Success! Invite(s) sent');
            }}
            loading={createParticipantLoading}
            orgUsersLoading={listOrgUsersLoading}
            maxInviteesError={maxInviteesError}
            titleFor="Participants"
            listOrgUsers={listOrgUsers}
            listAllFinishedAssessments={allFinishedAssessments}
            assessmentId={assessmentId}
          />
        )}
      </Box>
    </Box>
  );

  const viewTasks = () => (
    <>
      <Box>
        <Grid container>
          <Grid item xs>
            <AssessmentOverview
              allTasks={tableData}
              tasks={watchTasks}
              hours={hours}
              minutes={minutes}
              loading={allTasksAvailableToOrgLoading}
              specialtySkills={specialtySkillsData}
            />
            <FormProvider {...reactHookFormMethods}>
              <form>
                <TasksTable
                  listHintRevealsData={listHintRevealsData}
                  data={editTasks ? allTasks : assessmentTasks}
                  Controller={Controller}
                  control={control}
                  viewTaskActions={viewTaskActions}
                  editTasks={editTasks}
                  setEditTasksCallBack={setEditTasks}
                  viewAssessment
                  showEditButton={showEditButton()}
                  assessmentStatus={status}
                  revealHintsCallBack={(hintsDetails) => {
                    hintsDetails?.forEach((hintDetail) => {
                      createHintRevealMutation({
                        variables: {
                          input: {
                            hintId: hintDetail?.hintId,
                            taskAssessmentId: hintDetail?.taskAssessmentId,
                          },
                        },
                        onCompleted: () => {
                          refetchListHintReveals();
                          successToastMessage(
                            `All hints in ${hintDetail?.challengeName} are now revealed.`,
                          );
                        },
                      });
                    });
                  }}
                  isRefreshLoading={allTasksAvailableToOrgLoading}
                  onRefreshTasks={() => allTasksAvailableRefetch()}
                />
              </form>
            </FormProvider>
            {watchTasks?.length === 0 && editTasks && (
              <Box mt={2} mb={2}>
                <FdAlert
                  variant="error"
                  message="Please select challenge(s) for this assessment"
                />
              </Box>
            )}
          </Grid>
          <Grid
            item
            style={{
              width: openDrawer ? drawerWidth : 0,
            }}
          />
        </Grid>
      </Box>
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'assessor']}
          assessmentTasks={assessmentTasks}
        />
      )}
      {editTasks && (
        <>
          <FdButton size="large" variant="primary" onClick={onUpdateTasks}>
            {updateLoading ? 'Loading...' : 'Save'}
          </FdButton>
          <FdButton
            size="large"
            variant="tertiary"
            onClick={() => {
              setEditTasks(false);
              const previousTasks = assessmentTasks?.map((t) => t.id);
              // reset tasks on cancel
              setValue('taskIds', previousTasks);
            }}
          >
            CANCEL
          </FdButton>
        </>
      )}
    </>
  );

  const viewDetails = () => (
    <Box>
      {editDetails ? (
        <Details
          Controller={Controller}
          control={control}
          heading={
            <Box display="flex" justifyContent="space-between">
              Details
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setValue('name', assessmentData?.getAssessment?.name);
                      setValue(
                        'description',
                        assessmentData?.getAssessment?.description,
                      );
                      setEditDetails(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const details = await trigger(['name', 'description']);

                      if (details) {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              name: getValues('name'),
                              description: getValues('description'),
                            },
                          },
                          onCompleted:
                            successToastMessage('Assessment updated'),
                        });
                        setEditDetails(false);
                      }
                    }}
                  >
                    {updateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Details</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    disabled={!hasManagePermission}
                    onClick={() => setEditDetails(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          {!teamBased && <AssessmentType />}
          <Box mt={2}>
            <Typography variant="subtitle1">Name</Typography>
            <Typography variant="body1" color="secondary">
              {getValues('name')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">Description </Typography>
            <Typography variant="body1" color="secondary">
              {getValues('description')}
            </Typography>
          </Box>
        </FdCard>
      )}
      {editAvailability ? (
        <Availability
          Controller={Controller}
          control={control}
          showEdit={showEditButton()}
          editAvailability={editAvailability}
          heading={
            <Box display="flex" justifyContent="space-between">
              Availability
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setValue(
                        'startDateTime',
                        assessmentData?.getAssessment?.startDateTime,
                      );
                      setValue(
                        'endDateTime',
                        assessmentData?.getAssessment?.endDateTime,
                      );
                      setEditAvailability(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const availability =
                        status === 'Not Started'
                          ? await trigger(['startDateTime', 'endDateTime'])
                          : await trigger('endDateTime');

                      if (availability) {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              startDateTime: new Date(
                                getValues('startDateTime'),
                              ).toISOString(),
                              endDateTime: new Date(
                                getValues('endDateTime'),
                              ).toISOString(),
                            },
                          },
                          onCompleted:
                            successToastMessage('Assessment updated'),
                        });
                        setEditAvailability(false);
                      }
                    }}
                  >
                    {updateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Availability</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditAvailability(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="subtitle1">Assessment Start</Typography>
            <Typography variant="body1" color="secondary">
              {getValues('startDateTime') &&
                new Date(getValues('startDateTime')).toLocaleString()}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">Assessment End </Typography>
            <Typography variant="body1" color="secondary">
              {new Date(getValues('endDateTime')).toLocaleString()}
            </Typography>
          </Box>
        </FdCard>
      )}
      {editJumpbox ? (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Jump Box</Typography>
              <Box className="flex gap-2">
                <FdButton
                  variant="secondary"
                  size="small"
                  onClick={() => {
                    reset();
                    setEditJumpbox(false);
                    warningToastMessage('No changes were saved');
                  }}
                >
                  CANCEL
                </FdButton>
                <FdButton
                  variant="primary"
                  size="small"
                  onClick={async () => {
                    updateAssessmentMutation({
                      variables: {
                        input: {
                          id: assessmentId,
                          enableJumpbox: getValues('jumpbox')
                            ?.toString()
                            ?.toUpperCase(),
                          enableVPN: getValues('enableVPN')
                            ?.toString()
                            ?.toUpperCase(),
                        },
                      },
                      onCompleted: () => {
                        successToastMessage('Assessment  updated');
                      },
                    });
                    setEditJumpbox(false);
                  }}
                >
                  {updateLoading ? 'Loading...' : 'Save'}
                </FdButton>
              </Box>
            </Box>
          }
        >
          <Jumpbox editMode />
        </FdCard>
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Jump Box</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditJumpbox(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          <Box>
            <Typography variant="subtitle1">
              Enable VPN configuration
            </Typography>
            <Box mt={1}>
              {getValues('enableVPN') ? (
                <FdChip color="default" size="medium" label="VPN Enabled" />
              ) : (
                <Typography variant="body1" color="secondary">
                  VPN not enabled
                </Typography>
              )}
            </Box>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">Enable Jumpbox</Typography>
            <Switch checked={getValues('jumpbox')} />
          </Box>
        </FdCard>
      )}
      {editDuration ? (
        <Duration
          Controller={Controller}
          control={control}
          heading={
            <Box display="flex" justifyContent="space-between">
              Duration
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setValue('hours', assessmentData?.getAssessment?.hours);
                      setValue(
                        'minutes',
                        assessmentData?.getAssessment?.minutes,
                      );
                      setEditDuration(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const duration = await trigger(['hours', 'minutes']);

                      if (duration) {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              hours: getValues('hours'),
                              minutes: getValues('minutes'),
                            },
                          },
                          onCompleted:
                            successToastMessage('Assessment updated'),
                        });
                        setEditDuration(false);
                      }
                    }}
                  >
                    {updateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Duration</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditDuration(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="subtitle1">Duration</Typography>
            <Typography variant="body1" color="secondary">
              {formatDuration({
                hours: Number(getValues('hours')),
                minutes: Number(getValues('minutes')),
              })}
            </Typography>
          </Box>
        </FdCard>
      )}

      {editPreAssessment ? (
        <PreMessage
          Controller={Controller}
          control={control}
          heading={
            <Box display="flex" justifyContent="space-between">
              Pre-Assessment Message
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setValue(
                        'preMessage',
                        assessmentData?.getAssessment?.preMessage,
                      );
                      setEditPreAssessment(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const preMessageRes = await trigger('preMessage');

                      if (preMessageRes) {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              preMessage: getValues('preMessage'),
                              videoUrl: getValues('videoUrl'),
                            },
                          },
                          onCompleted:
                            successToastMessage('Assessment updated'),
                        });
                        setEditPreAssessment(false);
                      }
                    }}
                  >
                    {updateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Pre-Assessment Message</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditPreAssessment(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="body1" color="secondary">
              {getValues('preMessage')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">Video</Typography>
            <Typography variant="body1" color="secondary">
              {getValues('videoUrl') || 'Video not added'}
            </Typography>
          </Box>
        </FdCard>
      )}
      {editPostAssessment ? (
        <PostMessage
          Controller={Controller}
          control={control}
          heading={
            <Box display="flex" justifyContent="space-between">
              Post-Assessment Message
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setValue(
                        'postMessage',
                        assessmentData?.getAssessment?.postMessage,
                      );
                      setEditPostAssessment(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const postMessageRes = await trigger('postMessage');

                      if (postMessageRes) {
                        updateAssessmentMutation({
                          variables: {
                            input: {
                              id: assessmentId,
                              postMessage: getValues('postMessage'),
                            },
                          },
                          onCompleted:
                            successToastMessage('Assessment updated'),
                        });
                        setEditPostAssessment(false);
                      }
                    }}
                  >
                    {updateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Post-Assessment Message</Typography>
              <Box>
                {showEditButton() && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditPostAssessment(true)}
                  >
                    EDIT
                  </FdButton>
                )}
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="body1" color="secondary">
              {getValues('postMessage')}
            </Typography>
          </Box>
        </FdCard>
      )}
    </Box>
  );
  const assessmentName = getValues('name');

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: assessmentName, type: 'ASSESSMENT' }} />
      <BasePage
        type="ASSESSMENT"
        heading={assessmentName}
        data-cy="view-assessment"
        loading={assessmentDataLoading}
      >
        <FdModal
          size="xs"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Delete Participant?</span>
            </Box>
          }
          description={
            <Box>
              <Typography variant="subtitle1">
                Are you sure you want to delete the participant?
              </Typography>
              <Box mt={2}>
                <Typography variant="body1" color="secondary">
                  This will prevent the Participant from attempting the
                  assessment. The participant will not be notified that they
                  have been deleted.
                </Typography>
              </Box>
            </Box>
          }
          dismiss={
            removeParticipantLoading || removeInvitedUserLoading
              ? 'Loading...'
              : 'DELETE USER'
          }
          confirm="CANCEL"
          open={deleteParticipant}
          onDismiss={() => {
            if (deletedInvitedParticipantId) {
              removeInvitedUserMutation();
              setDeletedInvitedParticipantId('');
              successToastMessage(
                'The Participant has been successfully deleted',
              );
            }
            if (deletedParticipantId) {
              removeParticipantMutation();
              setDeletedParticipantId('');
              successToastMessage(
                'The Participant has been successfully removed',
              );
            }
          }}
          onConfirm={() => {
            setDeleteParticipant(false);
            setDeletedParticipantId('');
            setDeletedInvitedParticipantId('');
          }}
        />
        <FdTab
          label={[
            {
              label: 'Overview',
              tabRoute: `/assessor/view/${assessmentId}?tabindex=0`,
              index: 0,
              data: (
                <Overview
                  assessmentId={assessmentId}
                  setCustIndex={setRouteIndex}
                  setDefaultInsight={setDefaultInsight}
                  assessmentData={assessmentData}
                  attemptsData={listTaskAttemptsAggregateData}
                  serverTime={serverTime?.getSystemTime}
                  loading={
                    listTaskAttemptsAggregateDataLoading ||
                    assessmentDataLoading ||
                    groupsAssessmentDataLoading ||
                    tasksOpenedLoading
                  }
                  timeSpentDataForAssessment={timeSpentDataForAssessment}
                  tasksOpened={tasksOpened}
                />
              ),
            },
            {
              label: 'Insights',
              tabRoute: `/assessor/view/${assessmentId}?tabindex=1`,
              index: 1,
              data: (
                <Insights
                  defaultInsight={defaultInsight}
                  assessmentData={assessmentData}
                  attemptsData={listTaskAttemptsAggregateData}
                  teamBased={teamBased}
                  loading={
                    listTaskAttemptsAggregateDataLoading ||
                    assessmentDataLoading ||
                    allTasksLoading ||
                    allTasksAvailableToOrgLoading ||
                    groupsAssessmentDataLoading ||
                    tasksOpenedLoading
                  }
                  allTasks={tableData}
                  timeSpentDataForAssessment={timeSpentDataForAssessment}
                  specialtySkills={specialtySkillsData}
                  tasksOpened={tasksOpened}
                />
              ),
            },
            ...(globalSnap?.orgId !== orgId
              ? []
              : [
                  {
                    label: teamBased ? 'Teams' : 'Participants',
                    tabRoute: `/assessor/view/${assessmentId}?tabindex=2`,
                    index: 2,
                    data: teamBased ? (
                      <TeamsTab
                        orgId={
                          userOrgIdData?.userData?.getUserById?.items[0].orgId
                        }
                        assessmentId={assessmentId}
                        status={status}
                        statusColor={statusColor}
                        milliSecondsToFinish={milliSecondsToFinish}
                      />
                    ) : (
                      participants()
                    ),
                  },
                ]),
            ...(globalSnap?.orgId !== orgId
              ? []
              : [
                  {
                    label: 'Details',
                    tabRoute: `/assessor/view/${assessmentId}?tabindex=3`,
                    index: 3,
                    data: (
                      <FormProvider {...reactHookFormMethods}>
                        <form>{viewDetails()}</form>
                      </FormProvider>
                    ),
                  },
                ]),
            ...(globalSnap?.orgId !== orgId
              ? []
              : [
                  {
                    label: 'Challenges',
                    tabRoute: `/assessor/view/${assessmentId}?tabindex=4`,
                    index: 4,
                    data: viewTasks(),
                  },
                ]),
          ]}
          // default is details, sometimes participant
          index={parseInt(tabindex, 10)}
          custIndex={parseInt(custIndex, 10)}
          setCustIndex={setRouteIndex}
          tabLinkComponent={RouterLink}
        />
      </BasePage>
    </Box>
  );
};
export default ViewAssessment;
