import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Switch, FormControlLabel, FormGroup } from '@mui/material';
import {
  FdCard,
  FdTypography,
  FdButton,
  FdChip,
  FdAlert,
  FdModal,
} from '@fifthdomain/fe-shared';

const Jumpbox = ({ editMode }) => {
  const { control, setValue, getValues } = useFormContext();
  const [openVPN, setOpenVPN] = useState(false);
  const [enableVPN, setEnableVPN] = useState(
    getValues('enableVPN') ? getValues('enableVPN') : false,
  );
  const Wrapper = editMode ? Box : FdCard;

  return (
    <Wrapper
      variant="outlined"
      heading={!editMode && 'Advanced Networking Features'}
      data-cy="jumpbox"
    >
      <FdTypography variant="subtitle1">Enable VPN configuration</FdTypography>
      {!enableVPN ? (
        <Box mt={1}>
          <FdButton
            size="medium"
            variant="secondary"
            onClick={() => {
              setOpenVPN(true);
            }}
          >
            ENABLE VPN CONFIGURATION
          </FdButton>
        </Box>
      ) : (
        <Box mt={1}>
          <FdChip color="default" size="medium" label="VPN Enabled" />
        </Box>
      )}
      <Box>
        <Box mt={1}>
          <FdTypography color="secondary" variant="body2">
            When enabled, participants will have access to a VPN configuration
            options, allowing them to connect securely to their assessment
            environment.
          </FdTypography>
          <FdTypography style={{ color: 'red' }} variant="body2">
            Note: VPN configuration can’t be disabled once enabled.
          </FdTypography>
        </Box>
      </Box>
      <Controller
        control={control}
        name="jumpbox"
        render={({ field: { ref, value: fieldValue, ...rest } }) => (
          <Box my={2}>
            <FormGroup>
              <FdTypography variant="subtitle1">Enable Jump Box</FdTypography>
              <FormControlLabel
                control={
                  <Switch
                    id="jumpbox"
                    checked={fieldValue}
                    value={fieldValue?.value}
                    data-cy="jumpbox"
                    {...rest}
                    onChange={(e) => {
                      setValue('jumpbox', e.target.checked);
                    }}
                    inputRef={ref}
                  />
                }
              />
              <Box mt={1}>
                <FdTypography variant="body2" color="secondary">
                  When enabled, participants can connect to a Jump Box which
                  allows them to access assessments resources securely.
                </FdTypography>

                <FdAlert
                  style={{ marginTop: '4px' }}
                  variant="info"
                  message={
                    <FdTypography variant="body2">
                      Jump Box enablement settings cannot be edited once the
                      assessment has started. If enabled, participants will have
                      access to a Jump Box for the entire duration of the event.
                    </FdTypography>
                  }
                />
              </Box>
            </FormGroup>
          </Box>
        )}
      />
      <FdModal
        size="md"
        title=" Enable VPN configuration for participants?"
        description="Are you certain you want to enable VPN configuration for
            participants? Please be aware that this action is irreversible, and
            once enabled, VPN configuration cannot be disabled."
        confirm="ENABLE"
        dismiss="CANCEL"
        open={openVPN}
        onDismiss={() => {
          setOpenVPN(false);
        }}
        onConfirm={() => {
          setOpenVPN(false);
          setValue('enableVPN', true);
          setEnableVPN(true);
        }}
      />
    </Wrapper>
  );
};

Jumpbox.propTypes = {
  editMode: PropTypes.bool,
};

Jumpbox.defaultProps = {
  editMode: false,
};

export default Jumpbox;
