import { useState } from 'react';
import { getUrl } from 'aws-amplify/storage';
import { useAsyncEffect } from 'use-async-effect';

const useS3ImageLoader = (imageKey) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const getImageUrlFromS3 = async (key) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const imageData = await getUrl({
        path: `public/${key}`,
      });
      return imageData.url.toString();
    } catch (error) {
      throw error; 
    }
  };

  useAsyncEffect(
    async (isActive) => {
      setLoading(true);
      try {
        const imageS3Url = await getImageUrlFromS3(imageKey);
        if (!isActive()) return;
        setImageUrl(imageS3Url);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      } finally {
        setLoading(false);
      }
    },
    [imageKey],
  );

  return { loading, imageUrl };
};

export default useS3ImageLoader;
