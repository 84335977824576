import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  FdTable,
  FdButton,
  FdModal,
  FdTypography,
  FdChips,
  useSnapshot,
  globalStore,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TaskInfoCell from './TaskInfoCell';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';

const TasksTable = ({
  listHintRevealsData,
  data,
  editTasks,
  setEditTasksCallBack,
  viewTaskActions,
  viewAssessment,
  showEditButton,
  revealHintsCallBack,
  assessmentStatus,
  onRefreshTasks,
  isRefreshLoading,
}) => {
  const [revealAllHints, setRevealAllHints] = useState([]);
  const { control, setValue } = useFormContext();
  const globalSnap = useSnapshot(globalStore);
  const isAdminUser = globalSnap.userType === 'ADMIN';

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'creator',
      flex: 1,
      headerName: 'Creator/ Provider',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Creator/ Provider"
          tooltipText={
            <Box>
              Creator of the challenge is the manager/organisation which created
              that challenge. Creator information appears for challenges that
              are owned by your organisation.
              <br />
              <br />
              Provider name appears for challenges that have been leased from,
              (or provided by) other organisation.
            </Box>
          }
        />
      ),
      valueGetter: (params) => {
        const rowVal = params?.row;
        if (rowVal?.owned) {
          return rowVal?.creatorOrgId === rowVal?.ownerOrgId
            ? `Creator: ${rowVal.creator}`
            : `Creator: ${rowVal?.creatorOrg}`;
        }
        return `Provider: ${rowVal?.ownerOrg}`;
      },
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      valueGetter: (params) =>
        (params.value ?? []).length > 0 ? [...params.value].join(' ') : '',
      renderCell: (params) => getCommaSeparatedPlusSuffix(params?.row?.skills),
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params?.value),
    },
    {
      field: 'difficulty',
      flex: 1,
      headerName: 'Proficiency',
    },
    {
      field: 'estimatedSolveTime',
      flex: 1,
      headerName: 'Est. Time to Solve (mins)',
      type: 'number',
    },
    ...(globalSnap.orgPricingTier === 'STARTER'
      ? []
      : [
          {
            field: 'tags',
            flex: 1,
            headerName: 'Assigned Tags',
            renderHeader: () => (
              <TableHeaderColumnWithTooltip
                title="Assigned Tags"
                tooltipText={
                  <Box>
                    Tags help organize challenges and for general note-keeping.
                    You can create new tags using the Tag Directory in the
                    sidebar and apply them to Challenges via the Manage
                    Challenges page.
                    <br />
                    Visit Manage Challenges page by clicking Challenges under
                    Manage Content in the sidebar.
                  </Box>
                }
              />
            ),
            valueGetter: (params) =>
              (params.row?.tags ?? []).length > 0
                ? [...params.row?.tags].map((t) => t.Tag?.name ?? '')?.join(' ')
                : '',
            renderCell: (params) => {
              const _data = params.row?.tags
                ?.sort(sortByDateField('updatedAt', 'desc'))
                .map((t) => ({
                  label: t.Tag?.name,
                  color: getTagColor(t.Tag?.color),
                }));
              return _data?.length > 0 ? (
                <FdChips
                  data={_data}
                  numberOfChipsDisplayed={1}
                  caption="More"
                />
              ) : (
                <Box width="100%" textAlign="center">
                  -
                </Box>
              );
            },
          },
        ]),
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
      type: 'number',
    },
    {
      field: 'technologyTags',
      flex: 1,
      headerName: 'Technologies',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Technologies"
          tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    {
      field: 'techniqueTags',
      flex: 1,
      headerName: 'Techniques',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Techniques"
          tooltipText="Techniques refer to the specific methods or strategies required to resolve a challenge."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
  ];

  const editTask = viewAssessment ? editTasks : true;

  return (
    <Box>
      <Box
        mt={2}
        mb={2}
        height={editTask ? '650px' : '450px'}
        width="100%"
        style={{ backgroundColor: 'white' }}
        data-cy="tasks-table"
      >
        <Controller
          control={control}
          name="taskIds"
          render={({ field: { value } }) => (
            <FdTable
              defaultMuiToolbarSettings={{
                showMuiDefaultToolbar: true,
                columnsButton: true,
                filterButton: true,
                densityButton: true,
              }}
              columnVisibilityModel={{
                hintsReleased: !editTasks,
                hintsRevealAll: !editTasks && assessmentStatus !== 'Ended',
                difficulty: false,
                estimatedSolveTime: false,
                technologyTags: false,
                techniqueTags: false,
                creator: false,
                skills: false,
              }}
              slotProps={{
                columnsPanel: {
                  getTogglableColumns: () => [
                    'creator',
                    'specialtyName',
                    'skills',
                    'type',
                    'tags',
                    'recommendedPoints',
                    ...(!editTasks ? ['hintsReleased'] : []),
                    'technologyTags',
                    'techniqueTags',
                  ],
                  disableHideAllButton: true,
                  disableShowAllButton: true,
                },
              }}
              toolbarSettings={{
                title: (
                  <Box>
                    <FdTypography variant="h3"> Challenges</FdTypography>
                    <FdAlert
                      style={{ marginTop: '4px' }}
                      variant="info"
                      message={
                        <FdTypography variant="body2">
                          All labs in lab-based challenges have a duration of 2
                          hours. Participants can extend their lab instances as
                          many times as they require, resetting their lab timers
                          to 2 hours and retaining progress made. Participants
                          can also reset their labs as needed, which will deploy
                          a new lab instance and erase all progress.
                        </FdTypography>
                      }
                    />
                  </Box>
                ),
                headerActions: showEditButton
                  ? [
                      {
                        label: 'EDIT',
                        onClick: () => {
                          setEditTasksCallBack(true);
                        },
                      },
                    ]
                  : [],
                filterOptions: [],
                searchBox: true,
                filterButton: editTask,
                headerCustomElements: [
                  {
                    CustomElement: () =>
                      isAdminUser && (
                        <FdButton
                          startIcon={
                            isRefreshLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <RefreshIcon />
                            )
                          }
                          disabled={isRefreshLoading}
                          onClick={onRefreshTasks}
                        >
                          Refresh Challenges
                        </FdButton>
                      ),
                    styleClass: {},
                  },
                ],
              }}
              selectionModel={editTask ? value : []}
              onSelectionModelChange={(_values) => {
                if (_values?.length > 0) {
                  setValue('taskIds', _values);
                }
              }}
              selection={editTask}
              disableSelectionOnClick
              actions={viewTaskActions}
              rows={
                data?.map((task) => {
                  const _totalHints = task?.hints?.items;
                  const _hintRevealed =
                    listHintRevealsData?.listHintReveals?.items?.filter(
                      (item) =>
                        item?.taskAssessmentId?.includes(
                          task?.taskAssessmentId,
                        ),
                    );
                  const _remainToRevealHints = _totalHints
                    ?.filter(
                      ({ id }) =>
                        !_hintRevealed
                          ?.map((item) => item?.hintId)
                          .includes(id),
                    )
                    ?.map((item) => ({
                      hintId: item?.id,
                      taskAssessmentId: task?.taskAssessmentId,
                      challengeName: task?.name,
                    }));

                  return {
                    ...task,
                    difficulty: getDifficultyLabel(task.difficulty),
                    hintsReleased: _totalHints?.length
                      ? `${_hintRevealed?.length}(out of ${_totalHints?.length})`
                      : '-',
                    hintsRevealAll: _totalHints?.length
                      ? _remainToRevealHints
                      : null,
                  };
                }) || []
              }
              columns={columns}
              pagination
              visibleSelection
              rowsPerPageOptions={[5, 10, 20]}
              tablePageSize={10}
              gridId="assessor-admin-assessment-challenges"
            />
          )}
        />

        <FdModal
          size="xs"
          title="Reveal all Hints?"
          description={
            <Box>
              <FdTypography variant="body1">
                Once revealed, the hints can’t be hidden again.
              </FdTypography>
              <FdTypography variant="body1">
                Are you sure you want to reveal all the hints for this
                challenge?
              </FdTypography>
            </Box>
          }
          dismiss="Cancel"
          confirm="Reveal"
          open={revealAllHints?.length}
          onDismiss={() => {
            setRevealAllHints([]);
          }}
          onConfirm={() => {
            revealHintsCallBack(revealAllHints);
            setRevealAllHints([]);
          }}
        />
      </Box>
    </Box>
  );
};

TasksTable.defaultProps = {
  editTasks: true,
  setEditTasksCallBack: () => {},
  viewAssessment: false,
  viewTaskActions: [],
  showEditButton: undefined,
  listHintRevealsData: {},
  revealHintsCallBack: () => {},
  assessmentStatus: null,
  onRefreshTasks: () => null,
  isRefreshLoading: false,
};

TasksTable.propTypes = {
  assessmentStatus: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  editTasks: PropTypes.bool,
  setEditTasksCallBack: PropTypes.func,
  revealHintsCallBack: PropTypes.func,
  viewAssessment: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  viewTaskActions: PropTypes.array,
  showEditButton: PropTypes.bool,
  listHintRevealsData: PropTypes.shape({
    listHintReveals: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          hintId: PropTypes.string,
          taskAssessmentId: PropTypes.string,
        }),
      ),
    }),
  }),
  onRefreshTasks: PropTypes.func,
  isRefreshLoading: PropTypes.bool,
};

export default TasksTable;
