/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listCourseUsers = /* GraphQL */ `
  query ListCourseUser(
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseId
        messages(filter: { seen: { eq: FALSE } }) {
          items {
            id
            message
            userCourseId
            sender {
              id
              type
            }
            createdAt
          }
        }
        modulePartProgresses {
          items {
            id
            status
          }
        }
        course {
          orgId
          user {
            id
            org {
              name
            }
          }
          name
          description
          status
          availability
          categoryId
          category {
            name
          }
          image {
            bucket
            region
            key
          }
          available
          ownerId
          courseModules {
            items {
              id
              name
              description
              orderNumber
              parts {
                items {
                  id
                  name
                  description
                  type
                  file {
                    key
                    region
                    bucket
                  }
                  labId
                  lab {
                    id
                    name
                    description
                    provider
                    status
                    userId
                    public
                    shared
                    payload
                    orgId
                    createdAt
                    updatedAt
                  }
                  quiz {
                    name
                    duration
                  }
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                }
              }
            }
            nextToken
          }
          courseUsers {
            items {
              id
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        userId
        orgId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      nextToken
    }
  }
`;

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;
export const listGroupMembersByGroupId = /* GraphQL */ `
  query ListGroupMembersByGroupId($groupId: ID!, $orgId: ID) {
    listGroupMembersByGroupId(groupId: $groupId, orgId: $orgId) {
      id
      name
      email
      phone
      orgId
      alias
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          modulePartId
          modulePart {
            name
            description
            courseModuleId
            type
            duration
            expiry
            inactivityExpiry
            initialLabCount
            minLabCount
            deletionMode
            labId
          }
          task {
            type
            labId
            lab {
              id
              name
              vms {
                items {
                  id
                }
              }
              createdAt
              updatedAt
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      videoUrl
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          modulePartId
          modulePart {
            name
            description
            courseModuleId
            type
            duration
            expiry
            inactivityExpiry
            initialLabCount
            minLabCount
            deletionMode
            labId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          Tag {
            color
            id
            name
            orgId
          }
          updatedAt
        }
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        labId
        lab {
          name
        }
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
          org {
            id
            name
          }
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        userId
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        url
        name
        size
        type
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessmentAssessmentId
      groupId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          type
          name
        }
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          videoUrl
          createdBy
          participantEventType
          status
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskAttempt = /* GraphQL */ `
  query GetTaskAttempt($id: ID!) {
    getTaskAttempt(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getTaskOpened = /* GraphQL */ `
  query GetTaskOpened($id: ID!) {
    getTaskOpened(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        groupId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      cyberExperience
      highestEducation
      highestEducationOther
      cyberTraining
      formalTraining
      formalTrainingOther
      selfDirectedTraining {
        hours
      }
      workingIndustry
      workingIndustryOther
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserConsent = /* GraphQL */ `
  query GetUserConsent($id: ID!) {
    getUserConsent(id: $id) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const listUserConsents = /* GraphQL */ `
  query ListUserConsents(
    $filter: ModelUserConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        consentedOn
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsent = /* GraphQL */ `
  query GetConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listConsents = /* GraphQL */ `
  query ListConsents(
    $filter: ModelConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupUser = /* GraphQL */ `
  query GetGroupUser($id: ID!) {
    getGroupUser(id: $id) {
      id
      groupId
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupUsers = /* GraphQL */ `
  query ListGroupUsers(
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        status
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByParticipantEventType = /* GraphQL */ `
  query ListAssessmentsByParticipantEventType(
    $orgId: String!
    $participantEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByParticipantEventType(
      orgId: $orgId
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTasksByUserId = /* GraphQL */ `
  query ListTasksByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
          specialThemes {
            items {
              themeId
              theme {
                name
              }
            }
          }
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByGroupId = /* GraphQL */ `
  query ListUsersByGroupId(
    $groupId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByGroupId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            userId
          }
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByUserId = /* GraphQL */ `
  query ListGroupsByUserId(
    $userId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByUserId(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByGroupId = /* GraphQL */ `
  query ListAssessmentsByGroupId(
    $groupId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByGroupId(
      groupId: $groupId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByAssessmenId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskNotes = /* GraphQL */ `
  query ListTaskNotes(
    $filter: ModelTaskNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        taskId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
        }
        userAssessmentId
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        assessmentID
        notes
        groupId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listPostAssessmentReflectionQuestions = /* GraphQL */ `
  query ListPostAssessmentReflectionQuestions(
    $filter: ModelPostAssessmentReflectionQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostAssessmentReflectionQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listPostAssessmentReflectionAttempts = /* GraphQL */ `
  query ListPostAssessmentReflectionAttempts(
    $filter: ModelPostAssessmentReflectionAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostAssessmentReflectionAttempts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        questionId
        question {
          name
          id
          createdAt
          updatedAt
        }
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listLabInstances = /* GraphQL */ `
  query ListLabInstances(
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        labPrototypeId
        status
        modulePartId
        userId
        userAssessmentId
        expiry
        deletedAt
        createdAt
        updatedAt
        modulePartLabInstancesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVmConsole = /* GraphQL */ `
  query GetVmConsole($vmId: ID!, $type: INSTANCE_TYPE!) {
    getVmConsole(vmId: $vmId, type: $type) {
      url
      __typename
    }
  }
`;

export const getLabTime = /* GraphQL */ `
  query GetLabTime($labInstanceId: ID!) {
    getLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
      __typename
    }
  }
`;

export const listTaskOrgs = /* GraphQL */ `
  query ListTaskOrgs($limit: Int, $nextToken: String, $orgId: ID) {
    listTaskOrgs(
      filter: { orgId: { eq: $orgId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        orgId
        task {
          id
          name
          description
          summary
          type
          externalId
          orgId
          org {
            id
            name
          }
          specialtyId
          specialty {
            name
            skills {
              items {
                id
                name
              }
            }
          }
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
              techniqueTags {
                items {
                  techniqueTag {
                    name
                  }
                }
              }
            }
          }
          technologyTags {
            items {
              technologyTag {
                name
              }
            }
          }
          category
          difficulty
          competencies {
            items {
              area {
                areaName
              }
            }
          }
          hints {
            items {
              id
              taskId
              text
            }
          }
          tags(filter: { orgId: { eq: $orgId } }) {
            items {
              Tag {
                color
                id
                name
                orgId
              }
              updatedAt
            }
          }
          files {
            items {
              id
              name
              type
              url
              size
            }
          }
          recommendedPoints
          estimatedSolveTime
          userId
          user {
            id
            name
            org {
              id
              name
            }
          }
          modulePartId
          modulePart {
            name
            labId
            expiry
            lab {
              name
              status
            }
          }
          labId
          lab {
            id
            name
            vms {
              items {
                id
              }
            }
            createdAt
            updatedAt
          }
          status
          createdAt
          updatedAt
        }
        org {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTasksByOrgId = /* GraphQL */ `
  query ListTasksByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        summary
        type
        labId
        lab {
          id
          name
        }
        externalId
        orgId
        org {
          id
          name
        }
        specialtyId
        specialty {
          name
          skills {
            items {
              id
              name
            }
          }
        }
        skills {
          items {
            skillId
            skill {
              id
              name
              alias
            }
            techniqueTags {
              items {
                techniqueTag {
                  name
                }
              }
            }
          }
        }
        technologyTags {
          items {
            technologyTag {
              name
            }
          }
        }
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          items {
            area {
              areaName
            }
          }
        }
        assessments {
          nextToken
        }
        files {
          items {
            id
            name
            type
            url
            size
          }
        }
        hints {
          items {
            id
            taskId
            text
          }
        }
        tags(filter: { orgId: { eq: $orgId } }) {
          items {
            Tag {
              color
              id
              name
              orgId
            }
            updatedAt
          }
        }
        userId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
          org {
            id
            name
          }
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          duration
          expiry
          inactivityExpiry
          initialLabCount
          minLabCount
          labId
          lab {
            name
            status
          }
          url
          orderNumber
          status
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        orgs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getLabPrototype = /* GraphQL */ `
  query GetLabPrototype($id: ID!) {
    getLabPrototype(id: $id) {
      id
      name
      description
      provider
      status
      userId
      public
      shared
      payload
      vms {
        nextToken
        __typename
      }
      networks {
        nextToken
        __typename
      }
      instances {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      orgId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        event
        eventDateTime
        dimension1Id
        dimension1Name
        dimension2Id
        dimension2Name
        dimension3Id
        dimension3Name
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTimeSpentTasks = /* GraphQL */ `
  query GetTimeSpentTasks($userAssessmentId: ID!, $userId: ID, $groupId: ID) {
    getTimeSpentTasks(
      userAssessmentId: $userAssessmentId
      userId: $userId
      groupId: $groupId
    ) {
      taskId
      timeSpent
    }
  }
`;

export const listProductsByOrgId = /* GraphQL */ `
  query ListProductsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        product {
          id
          name
        }
      }
    }
  }
`;

export const listDashboardsByOrgId = /* GraphQL */ `
  query ListDashboardsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        orgId
        org {
          id
          name
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        productId
        product {
          id
          name
          description
          createdAt
          updatedAt
        }
        thumbnail {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      description
    }
  }
`;

export const listModuleParts = /* GraphQL */ `
  query ListModuleParts(
    $filter: ModelModulePartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        description
        courseModuleId
        type
        duration
        expiry
        inactivityExpiry
        initialLabCount
        minLabCount
        deletionMode
        labId
        lab {
          id
          name
        }
        orderNumber
        status
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listRoleCompetencies = /* GraphQL */ `
  query ListRoleCompetencies(
    $filter: ModelRoleCompetencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleCompetencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competencyKSAID
        competencyWorkRoleID
        competencyWorkRole {
          id
          roleCode
          workRole
          description
          areaId
          categoryId
          area {
            areaCode
            areaName
          }
          category {
            categoryName
          }
        }
        competencyKSA {
          id
          ksaCode
          description
          ksaType
        }
      }
      nextToken
    }
  }
`;
export const getUsersAssessmentTimeSpent = /* GraphQL */ `
  query GetUsersAssessmentTimeSpent($assessmentId: ID!) {
    getUsersAssessmentTimeSpent(assessmentId: $assessmentId) {
      userId
      taskId
      timeSpent
    }
  }
`;

export const listTaskAttemptAggregates = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        assessmentId
        taskId
        levels
        status
        points
        userAssessmentId
        attempts
        createdAt
        updatedAt
        task {
          id
          name
          difficulty
          summary
          description
          recommendedPoints
          estimatedSolveTime
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
                alias
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listTaskOpenedsByUserAssessmentId = /* GraphQL */ `
  query ListTaskOpenedsByUserAssessmentId(
    $taskOpenedUserAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpenedsByUserAssessmentId(
      taskOpenedUserAssessmentId: $taskOpenedUserAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        taskOpenedUserAssessmentId
        assessmentId
        levelId
        startedSolving
        startedSolvingAt
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;

export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        teamBased
        hours
        minutes
        story
        preMessage
        postMessage
        participantEventType
        createdBy
        hideScoreBoard
        status
        tasks {
          items {
            id
            task {
              id
              description
              estimatedSolveTime
              recommendedPoints
              files {
                items {
                  id
                  url
                  name
                  size
                  type
                }
              }
              type
              recommendedPoints
              type
              labId
              name
              difficulty
              competencies {
                items {
                  area {
                    areaName
                  }
                }
              }
              technologyTags {
                items {
                  technologyTag {
                    name
                  }
                }
              }
              skills {
                items {
                  techniqueTags {
                    items {
                      techniqueTag {
                        name
                      }
                    }
                  }
                  skill {
                    name
                    alias
                  }
                }
              }
              specialty {
                name
              }
            }
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listSpecialties = /* GraphQL */ `
  query ListSpecialties(
    $filter: ModelSpecialtyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        skills {
          items {
            id
            name
            description
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getLabInstance = /* GraphQL */ `
  query GetLabInstance($id: ID!) {
    getLabInstance(id: $id) {
      id
      labPrototypeId
      status
      modulePartId
      userId
      userAssessmentId
      vms {
        nextToken
        __typename
      }
      expiry
      deletedAt
      createdAt
      updatedAt
      modulePartLabInstancesId
      __typename
    }
  }
`;
export const listJumpboxInstancesByUserAssessmentId = /* GraphQL */ `
  query ListJumpboxInstancesByUserAssessmentId(
    $userAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJumpboxInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJumpboxInstancesByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jumpboxPrototypeId
        status
        userId
        userAssessmentId
        failureReason
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const queryTemplatesByStatus = /* GraphQL */ `
  query QueryTemplatesByStatus(
    $status: TEMPLATE_STATUS!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTemplatesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        tasks {
          items {
            taskId
            task {
              id
              name
              type
              description
              summary
              externalId
              orgId
              org {
                name
              }
              category
              difficulty
              specialtyId
              specialty {
                id
                name
                description
                createdAt
                updatedAt
              }
              skills {
                items {
                  id
                  skillId
                  skill {
                    id
                    name
                  }
                  techniqueTags {
                    items {
                      techniqueTag {
                        name
                      }
                    }
                  }
                }
                nextToken
              }
              technologyTags {
                items {
                  technologyTagID
                  technologyTag {
                    id
                    name
                  }
                }
              }
              attempts(filter: { success: { eq: true } }) {
                items {
                  id
                }
              }
              competencies {
                items {
                  workRole {
                    area {
                      areaName
                    }
                  }
                }
              }
              hints {
                items {
                  id
                  taskId
                  text
                }
              }
              tags {
                items {
                  Tag {
                    color
                    id
                    name
                    orgId
                  }
                  updatedAt
                }
              }
              files {
                items {
                  id
                  name
                  type
                  url
                  size
                }
              }
              userId
              user {
                id
                name
                type
                orgId
              }
              recommendedPoints
              estimatedSolveTime
              userId
              modulePartId
              labId
              lab {
                id
                name
                vms {
                  items {
                    id
                  }
                }
                createdAt
                updatedAt
              }
              status
              createdAt
              updatedAt
            }
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listLabInstancesByModulePartId = /* GraphQL */ `
  query ListLabInstancesByModulePartId(
    $modulePartId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstancesByModulePartId(
      modulePartId: $modulePartId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labPrototypeId
        status
        modulePartId
        userId
        userAssessmentId
        expiry
        deletedAt
        createdAt
        updatedAt
        modulePartLabInstancesId
        vms {
          items {
            id
            name
            labPrototypeVmId
            vpn
            vdi
          }
          nextToken
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getModulePart = /* GraphQL */ `
  query GetModulePart($id: ID!) {
    getModulePart(id: $id) {
      id
      name
      type
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      deletionMode
      labId
      status
    }
  }
`;

export const listTaskOpenedsByAssessmentId = /* GraphQL */ `
  query ListTaskOpenedsByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpenedsByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        task {
          id
          name
          description
          summary
          externalId
          orgId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          modulePartId
          labId
          status
          type
          public
          specialtyId
          solutionVideo
          solutionSteps
          createdAt
          updatedAt
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
                alias
              }
            }
          }
          __typename
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          __typename
        }
        taskOpenedUserAssessmentId
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          multiLevel
          level
          story
          preMessage
          postMessage
          createdBy
          participantEventType
          hideScoreBoard
          guided
          showPostAssessmentReflection
          status
          availabilityType
          enableJumpbox
          modulePartId
          createdAt
          updatedAt
          __typename
        }
        groupId
        levelId
        level {
          id
          assessmentId
          name
          levelNumber
          createdAt
          updatedAt
          __typename
        }
        startedSolving
        startedSolvingAt
        createdAt
        updatedAt
        taskOpenedTaskId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listTaskAttemptsByAssessmentId = /* GraphQL */ `
  query ListTaskAttemptsByAssessmentId(
    $assessmentId: ID!
    $taskAttemptTaskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptsByAssessmentId(
      assessmentId: $assessmentId
      taskAttemptTaskId: $taskAttemptTaskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
          competencies {
            items {
              id
              category {
                categoryCode
                categoryName
              }
              area {
                areaCode
                areaName
              }
              competency {
                description
                id
              }
            }
          }
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
              }
            }
          }
        }
        taskAttemptTaskId
        userAssessment {
          id
          userId
          user {
            alias
            id
            name
          }
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          __typename
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        levelId
        level {
          id
          assessmentId
          name
          levelNumber
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listTaskAttemptAggregatesByAssessmentId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByAssessmentId(
    $assessmentId: ID!
    $taskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByAssessmentId(
      assessmentId: $assessmentId
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          hmacId
          currentOccupation
          location
          workStatus
          gender
          neurodiversity
          ethnicity
          jobPool
          remote
          workArrangement
          relocate
          minSalary
          maxSalary
          createdAt
          updatedAt
          __typename
        }
        groupId
        group {
          id
          name
          description
          orgId
          createdAt
          updatedAt
          __typename
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          multiLevel
          level
          story
          preMessage
          postMessage
          createdBy
          participantEventType
          hideScoreBoard
          guided
          showPostAssessmentReflection
          status
          availabilityType
          enableJumpbox
          modulePartId
          createdAt
          updatedAt
          __typename
        }
        taskId
        task {
          id
          name
          difficulty
          summary
          description
          recommendedPoints
          estimatedSolveTime
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
                alias
              }
            }
          }
        }
        levels
        status
        points
        userAssessmentId
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          __typename
        }
        attempts
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listChallengesRatingsByUserAssessmentId = /* GraphQL */ `
  query ListChallengesRatingsByUserAssessmentId(
    $userAssessmentId: ID!
    $taskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengesRatingsByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userAssessmentId
        taskId
        ratings
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listVPNVMsByLabInstanceId = /* GraphQL */ `
  query ListVPNVMsByLabInstanceId(
    $labInstanceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVPNVMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVPNVMsByLabInstanceId(
      labInstanceId: $labInstanceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labInstanceVmId
        externalIp
        labInstanceId
        assessmentId
        userAssessmentId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const downloadVPNConfigureFile = /* GraphQL */ `
  query DownloadVPNConfigureFile(
    $userAssessmentId: ID!
    $assessmentId: ID!
    $orgId: ID!
  ) {
    downloadVPNConfigureFile(
      userAssessmentId: $userAssessmentId
      assessmentId: $assessmentId
      orgId: $orgId
    )
  }
`;
