import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTable, FdChip as Chip, FdSkeleton } from '@fifthdomain/fe-shared';
import { getParticipantAssessmentStatusColor } from '../../shared/utils/getStatusColor';
import { getProficiencyLevel } from '../../shared/utils/difficultyMapping';
import TaskActivity from './TaskActivity';
import SpecialtyIcon from './SpecialtyIcon';
import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';

const TasksTable = ({
  actions,
  rows,
  headerActions,
  onRowClick,
  teamBased,
  loading,
}) => {
  const columns = [
    {
      field: 'icon',
      headerName: ' ',
      width: 80,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <SpecialtyIcon specialty={params?.row?.specialty} />
      ),
    },
    { field: 'name', width: 350, headerName: 'Name' },
    { field: 'specialty', width: 200, headerName: 'Professional Specialty' },
    ...(teamBased
      ? [
          {
            field: 'activity',
            width: 200,
            headerName: 'Activity',
            valueGetter: (params) => params?.row?.activities,
            renderCell: (params) =>
              params?.row?.status !== 'Completed' && (
                <TaskActivity activities={params?.row?.activities} />
              ),
          },
        ]
      : []),
    {
      field: 'difficulty',
      width: 150,
      headerName: 'Proficiency',
      renderCell: (params) => getProficiencyLevel(params.row.difficulty),
    },
    {
      field: 'skills',
      width: 250,
      headerName: 'Skills',
      valueGetter: (params) =>
        params?.value?.length > 0 ? [...params?.value]?.join(' ') : '',
      renderCell: (params) => getCommaSeparatedPlusSuffix(params?.row?.skills),
    },
    {
      field: 'type',
      width: 150,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params.value),
    },
  ];

  columns.push({
    field: 'status',
    headerName: 'Status',
    width: 140,
    valueGetter: (params) => params?.row?.status,
    renderCell: (params) => (
      <FdSkeleton loading={loading} height={24} width={73}>
        <Chip
          color={getParticipantAssessmentStatusColor(params?.row?.status)}
          size="small"
          label={params?.row?.status}
        />
      </FdSkeleton>
    ),
  });
  columns.push({
    field: 'attempts',
    width: 120,
    headerName: 'Attempts',
    renderCell: (params) => (
      <FdSkeleton loading={loading} height={24} width={73}>
        <span className="text-sm">{params?.value}</span>
      </FdSkeleton>
    ),
  });

  return (
    <Box height="715px" data-cy="tasks-table">
      <FdTable
        toolbarSettings={{
          title: 'Challenges',
          headerActions,
          filterOptions: [],
          filterButton: true,
          searchBox: true,
        }}
        selection={false}
        actions={actions}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        onRowClick={onRowClick}
        rowClickable
        gridId="assessor-participant-challenges-table"
      />
    </Box>
  );
};

TasksTable.defaultProps = {
  actions: [],
  headerActions: [],
  onRowClick: () => {},
  teamBased: false,
};

TasksTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  onRowClick: PropTypes.func,
  teamBased: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default TasksTable;
