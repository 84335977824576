import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { FdButton, FdTextField } from '@fifthdomain/fe-shared';

const FlagSubmission = ({ onClickSubmit, loading, control, Controller }) => {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box mt={1}>
        <Controller
          control={control}
          name="flag"
          render={({ field, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="flag"
                label="Submit Flag"
                required
                fullWidth
                placeholder="FLAG{text}"
                error={error}
                helperText={error && error.message}
                data-cy="flag"
                {...field}
              />
            </Box>
          )}
        />
        <Box mt={1}>
          <FdButton
            size="large"
            disabled={loading}
            data-cy="submit-button"
            onClick={onClickSubmit}
          >
            {loading ? (
              <Box width="60px">
                <CircularProgress size="2rem" />
              </Box>
            ) : (
              'Submit'
            )}
          </FdButton>
        </Box>
      </Box>
    </form>
  );
};

FlagSubmission.propTypes = {
  onClickSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  Controller: PropTypes.elementType.isRequired,
};

export default FlagSubmission;
