import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdCard, FdMarkdownRender, FdTypography } from '@fifthdomain/fe-shared';
import TeamMembers from '../components/Participant/TeamMembers';

const AssessmentRules = ({
  userFullName,
  preMessage,
  videoUrl,
  assessmentData,
}) => (
  <Box>
    <FdCard heading={`Welcome ${userFullName}!`} data-cy="welcome-card">
      <Box>
        <FdMarkdownRender markdown={preMessage} />
      </Box>
      {videoUrl && (
        <Box mt={3}>
          <FdTypography variant="h4">Instructional Video</FdTypography>
          <div
            style={{ maxWidth: '80%', marginTop: '16px' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: videoUrl,
            }}
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </Box>
      )}
      <Box>
        <TeamMembers assessmentData={assessmentData} />
      </Box>
    </FdCard>
  </Box>
);

AssessmentRules.propTypes = {
  userFullName: PropTypes.string.isRequired,
  preMessage: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assessmentData: PropTypes.object.isRequired,
  videoUrl: PropTypes.string,
};
AssessmentRules.defaultProps = {
  videoUrl: undefined,
};

export default AssessmentRules;
