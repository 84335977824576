import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const BlobWithDescription = ({ color, label }) => {
  return (
    <Box display="flex" mr={2}>
      <Box
        width={12}
        height={12}
        style={{ radius: '2px', background: color }}
      />
      <Box ml={0.5}>
        <FdTypography variant="captiontext2">{label}</FdTypography>
      </Box>
    </Box>
  );
};

BlobWithDescription.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default BlobWithDescription;
