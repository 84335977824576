import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, isValid as isDateValid } from 'date-fns';
import { Box, IconButton, styled, useTheme, colors } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  FdTable,
  FdSkeleton,
  FdButton,
  FdTypography,
  FdTooltip,
  FdCard,
  FdModal,
  FdIconWithTooltip,
  FdDateTimePicker,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import {
  getCommaSeparated,
  getCommaSeparatedPlusSuffix,
} from '../../../shared/utils/stringUtils';
import { sortByDateField } from '../../../shared/utils/dateUtils';
import { Summary } from '../../Assessment';
import { PROFICIENCY_LEVELS, PROFICIENCY } from '../../../constants';
import {
  calculatePercentagePositionWithinSlot,
  generateTimeline,
  getElapsedTime,
} from './utils';
import { getArrayByLength } from '../../../shared/utils/objectUtils';
import FDTechGraphs from './FDTechGraphs';

const InfoIcon = () => (
  <IconButton size="small" style={{ marginLeft: '3px', marginBottom: '5px' }}>
    <InfoOutlinedIcon />
  </IconButton>
);
const ProficiencyLabel = ({ label, tooltip, indicator }) => (
  <Box className="flex items-center">
    <Box mr={1}>{indicator}</Box>
    <FdTypography variant="body2">{label}</FdTypography>
    <FdTooltip title={tooltip} style={{ marginTop: '4px' }}>
      <span>
        <InfoIcon />
      </span>
    </FdTooltip>
  </Box>
);
ProficiencyLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
  indicator: PropTypes.node.isRequired,
};

const EVENT_POSITION = {
  O: '59px',
  S: '43px',
  A: '27px',
  star: '13px',
};

const HoverBox = styled(Box)(({ highlight }) => ({
  transition: 'transform 0.3s ease-in-out',
  zIndex: 100,
  '&:hover': {
    zIndex: 500,
    border: '1px solid white',
    paddingBottom: highlight === 'star' ? 'inherit' : '1rem',
    transform:
      highlight === 'O'
        ? 'scale(1.5) translateY(3px)'
        : highlight === 'star'
          ? 'scale(1.5) translateY(-5px)'
          : 'scale(1.5)',
  },
}));

const EventIndicator = ({ eventText }) => {
  const color = { O: '#2196F3', S: '#8E24AA', A: '#EF5350' };
  if (eventText === 'star') {
    return (
      <HoverBox
        className="relative rounded-full h-5 w-5 flex items-center justify-center"
        style={{ backgroundColor: '#4CAF50' }}
        highlight={eventText}
      >
        <StarIcon className="fill-current text-white p-1" />
      </HoverBox>
    );
  }
  return (
    <HoverBox
      style={{
        backgroundColor: color[eventText],
        borderLeft: '1px solid #fff',
      }}
      highlight={eventText}
      className="relative h-4 w-4 flex align-center justify-center rounded-sm"
    >
      <Box className="text-white text-xs">{eventText}</Box>
    </HoverBox>
  );
};
EventIndicator.propTypes = {
  eventText: PropTypes.string.isRequired,
};

const eventMap = { O: 'Opened', S: 'Started', A: 'Attempted', star: 'Solved' };
const EventTile = ({ progression }) => {
  const progressionSorted = progression?.sort(
    (a, b) =>
      Object.keys(eventMap).indexOf(a.event) -
      Object.keys(eventMap).indexOf(b.event),
  );
  const event =
    progressionSorted?.length > 0
      ? progressionSorted[(progressionSorted?.length || 0) - 1]?.event
      : '';

  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
    >
      {event && (
        <Box display="flex" alignItems="center">
          <EventIndicator eventText={event} />
          <Box ml={1}>{`${eventMap[event]}`}</Box>
        </Box>
      )}
      {event !== 'star' && (
        <FdTypography variant="captiontext1" color="secondary">
          Not Solved
        </FdTypography>
      )}
    </Box>
  );
};
EventTile.propTypes = {
  progression: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const FDProficiencyApproachTable = ({
  taskAttempts,
  taskAttemptsDetail,
  startDateTime,
  selectedUserIds,
  tasksOpened,
  tasks,
  completedTasks,
  ...props
}) => {
  const [selectedTask, setSelectedTask] = useState(undefined);
  const theme = useTheme();
  const { green, red } = colors;
  const isDarkTheme = theme?.palette?.type === 'dark';
  const reactHookFormMethods = useForm({
    defaultValues: { progressionStartDate: null, progressionEndDate: null },
    resolver: yupResolver(
      Yup.object().shape({
        progressionStartDate: Yup.date()
          .required('Please enter a Start Date and Time')
          .typeError('Please enter a valid Start Date and Time')
          .max(
            Yup.ref('progressionEndDate'),
            'Start DateTime must be before End DateTime',
          )
          .max(new Date(), 'Start Date and Time cannot be in the future'),
        progressionEndDate: Yup.date()
          .required('Please enter an End Date and Time')
          .typeError('Please enter a valid End Date and Time')
          .min(
            Yup.ref('progressionStartDate'),
            'End Date Time must be after Start DateTime',
          )
          .max(new Date(), 'End Date and Time cannot be in the future'),
      }),
    ),
    mode: 'all',
  });
  const { control, setValue, watch } = reactHookFormMethods;
  const allEventRows = useMemo(() => {
    return [
      ...taskAttemptsDetail.map((ta) => ({
        taskId: ta?.task?.id,
        event: ta?.success ? 'star' : 'A',
        dateTime: ta?.createdAt,
        solvedBy: ta?.success ? ta?.userAssessment?.user?.alias : '-',
        solvedByUserId: ta?.success && ta?.userId,
      })),
      ..._(tasksOpened.filter((t) => selectedUserIds.includes(t.userId)))
        .groupBy('taskOpenedTaskId')
        .map((_tasks, taskId) => ({
          taskId,
          event: 'O',
          dateTime: _.minBy(_tasks, 'createdAt').createdAt,
        }))
        .value(),
      ...tasksOpened
        .filter(
          (to) => selectedUserIds.includes(to.userId) && to?.startedSolving,
        )
        .map((to) => ({
          taskId: to?.taskOpenedTaskId,
          event: 'S',
          dateTime: to?.startedSolvingAt,
        })),
    ];
  }, [taskAttemptsDetail, tasksOpened, selectedUserIds]);
  const maxEventDateTime = useMemo(() => {
    return (
      _.maxBy(allEventRows, (item) => new Date(item.dateTime))?.dateTime ||
      new Date()
    );
  }, [allEventRows]);

  const progressionData = [...allEventRows.sort(sortByDateField('dateTime'))];
  const progressionStartDate = watch('progressionStartDate');
  const progressionEndDate = watch('progressionEndDate');
  const progressionDataForGraph = progressionData?.map((pd) => ({
    ...pd,
    eventActionTime: new Date(pd.dateTime),
    givenTime: `${getElapsedTime(pd.dateTime, startDateTime)} - ${format(
      new Date(pd.dateTime),
      'dd/MM - HH:mm:ss',
    )}`,
  }));

  useEffect(() => {
    setValue('progressionStartDate', new Date(startDateTime));
    setValue('progressionEndDate', new Date(maxEventDateTime));
  }, [startDateTime, maxEventDateTime, setValue]);

  const progressiveTimeLine = generateTimeline(
    progressionStartDate,
    progressionEndDate,
    startDateTime,
  );
  const rowBorderColor = isDarkTheme ? 'rgba(255, 255, 255, 0.12)' : 'inherit';

  const columns = [
    {
      field: 'taskName',
      width: 350,
      headerName: 'Challenge Name',
      renderCell: (params) => {
        return (
          <Box>
            <FdTypography variant="subtitle2">{params.value}</FdTypography>
            <FdHighlightChip text={PROFICIENCY_LEVELS[params.row.difficulty]} />
          </Box>
        );
      },
    },
    {
      field: 'skills',
      width: 190,
      headerName: 'Skills',
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Skills</FdTypography>
            <FdTooltip title="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort.">
              <span>
                <InfoIcon />
              </span>
            </FdTooltip>
          </Box>
        );
      },
      valueGetter: (params) =>
        params?.value?.length > 0 ? [...(params?.value || '')]?.join(' ') : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(params?.row?.skills);
      },
    },
    {
      field: 'solvedStatus',
      headerName: 'Status',
      width: 100,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box className="flex flex-col items-center justify-center">
            {params?.value === 'Solved' ? (
              <CheckCircleIcon style={{ color: green[700] }} />
            ) : (
              <ErrorIcon style={{ color: red[300] }} />
            )}
            <FdTypography color="secondary" variant="captiontext2">
              {params?.value}
            </FdTypography>
          </Box>
        );
      },
    },
    {
      field: 'progression',
      headerName: 'Progression',
      filterable: false,
      unSort: true,
      flex: 2,
      renderHeader: () => {
        return (
          <Box className="w-full overflow-visible">
            <Box className="flex items-center w-full justify-between">
              <Box style={{ marginBottom: '7px' }}>
                <FdTypography variant="subtitle2">Progression</FdTypography>
              </Box>
              <FdCard
                variant="outlined"
                className="flex items-center h-11 ml-6 w-100"
              >
                <Box className="flex gap-x-6 h-8">
                  <ProficiencyLabel
                    label="Open"
                    tooltip="A “Open” event is when a participant opens a challenge and views its basic details."
                    indicator={<EventIndicator eventText="O" />}
                  />
                  <ProficiencyLabel
                    label="Start"
                    tooltip="After having viewed the basic details, a “Start” event occurs when a participant engages further transitioning into the expanded challenge view. The expanded challenge view encompasses attachments, lab environments, and hints."
                    indicator={<EventIndicator eventText="S" />}
                  />
                  <ProficiencyLabel
                    label="Attempt"
                    tooltip="An “Attempt” is logged when a participant submits their response or FLAG for the challenge. Candidates have the flexibility to make multiple attempts according to their preference. Nonetheless, their efficiency rating gradually diminishes with each subsequent attempt."
                    indicator={<EventIndicator eventText="A" />}
                  />
                  <ProficiencyLabel
                    label="Solved"
                    tooltip="A challenge is marked “Solved” when the participant enters the correct FLAG"
                    indicator={<EventIndicator eventText="star" />}
                  />
                </Box>
              </FdCard>
            </Box>
            <Box
              className="w-full h-10 flex items-center"
              style={{
                height: '38px',
              }}
            >
              {progressiveTimeLine.map((tl) => (
                <Box
                  className="flex items-center h-full"
                  style={{
                    flex: 1,
                  }}
                >
                  <Box className="flex flex-col">
                    <Box
                      className="px-1"
                      style={{
                        backgroundColor: 'rgb(255, 237, 213)',
                        borderRadius: '4px',
                      }}
                    >
                      <FdTypography
                        variant="captiontext2"
                        color="secondary"
                        style={{ fontWeight: '500', color: 'black' }}
                      >
                        {tl.timeFormatted}
                      </FdTypography>
                    </Box>
                    <FdTypography
                      variant="captiontext1"
                      style={{ fontWeight: '500' }}
                    >
                      {tl.duration}
                    </FdTypography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        );
      },
      renderCell: (params) => {
        const progression = params.value;
        return (
          <Box className="w-full">
            <Box className="flex items-center">
              {getArrayByLength(10, (__, i) => (
                <Box
                  style={{
                    borderLeft:
                      i === 0 ? `1px solid ${rowBorderColor}` : 'none',
                    flex: 1,
                    height: '52px',
                    borderRight: `1px solid ${rowBorderColor}`,
                  }}
                />
              ))}
            </Box>
            <Box className="flex items-center">
              {progressiveTimeLine.map((tl) => {
                const progressionForDisplay =
                  progression
                    ?.filter(
                      (p) =>
                        new Date(p?.eventActionTime) >= tl.slotStart &&
                        new Date(p?.eventActionTime) <= tl.slotEnd,
                    )
                    ?.map((p) => ({
                      ...p,
                      position: calculatePercentagePositionWithinSlot(
                        new Date(p?.eventActionTime),
                        tl.slotStart,
                        tl.slotEnd,
                      ),
                    })) || [];
                if (progressionForDisplay.length === 0) {
                  return <Box style={{ flex: 1 }} />;
                }
                return (
                  <Box style={{ flex: 1 }}>
                    {progressionForDisplay?.map((p) => (
                      <Box className="w-full h-full relative pl-2 overflow-visible">
                        <Box
                          className="flex flex-col items-center absolute h-full"
                          style={{
                            bottom: EVENT_POSITION[p.event],
                            left: `${p.position}%`,
                          }}
                        >
                          <FdTooltip title={p.givenTime}>
                            <span style={{ position: 'relative' }}>
                              <EventIndicator eventText={p.event} />
                            </span>
                          </FdTooltip>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      },
    },
  ];
  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      CustomElement: (row) => {
        const { rowData } = row;
        return (
          <FdButton
            variant="tertiary"
            size="small"
            onClick={() => setSelectedTask(rowData)}
          >
            View
          </FdButton>
        );
      },
    },
  ];

  const rows = _([
    ...taskAttempts,
    ...tasksOpened.map((to) => ({ ...to, taskId: to.taskOpenedTaskId })),
  ])
    .groupBy('taskId')
    .map((items, taskId) => {
      const { task } = items[0] ?? {};
      const progData =
        progressionDataForGraph.filter((pg) => pg.taskId === taskId) || [];
      const solvedData = progData?.find((pd) => pd?.event === 'star') || {};

      return {
        ...task,
        id: taskId,
        taskName: task?.name,
        difficulty: task?.difficulty,
        skills: task?.skills?.items.map(({ skill }) => skill?.name),
        progression: progData
          ?.sort(sortByDateField('dateTime'))
          ?.map((pgd) => ({
            event: pgd.event,
            givenTime: `${eventMap[pgd.event]} - ${pgd.givenTime}`,
            eventActionTime: pgd.eventActionTime,
          })),
        skillsFormatted: task?.skills?.items.map(({ skill }) => ({
          name: skill?.name,
          alias: skill?.alias,
        })),
        attemptsCount:
          taskAttemptsDetail?.filter((ta) => ta.task?.id === taskId)?.length ||
          0,
        solvedBy: solvedData?.solvedBy || '-',
        solvedByUserId: solvedData?.solvedByUserId,
        solvedStatus: solvedData?.solvedByUserId ? 'Solved' : 'Unsolved',
      };
    })
    .filter((t) => t.taskName)
    .value();

  const modalTasks = {
    items: tasks?.items?.filter((t) => t?.taskId === selectedTask?.id) || [],
  };
  const modalCompletedTasks =
    completedTasks?.filter((ct) => ct?.taskId === selectedTask?.id) || [];

  return (
    <>
      <FdSkeleton height="532px">
        <FormProvider {...reactHookFormMethods}>
          <FdCard variant="outlined">
            <Box className="flex items-center justify-end gap-x-6">
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="subtitle2" color="secondary">
                  Select Date and Time Range:
                </FdTypography>
                <FdIconWithTooltip
                  title={
                    <Box>
                      You can filter the Challenges Approach table by selecting
                      a date and date and time range between start date and the
                      current date or training end date.
                      <br />
                      <br />
                      By default:
                      <ul className="list-disc pl-5">
                        <li>
                          For &apos;Ended&apos; trainings, the range is set from
                          the training start date to the end date.
                        </li>
                        <li>
                          For &apos;In-Progress&apos; trainings, the range is
                          set from the training start date to the current date.
                        </li>
                      </ul>
                    </Box>
                  }
                />
              </Box>
              <Box className="flex items-center gap-x-3">
                <Controller
                  control={control}
                  name="progressionStartDate"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FdDateTimePicker
                      label="Start Date and Time"
                      value={rest.value}
                      helperText={
                        rest.value && !isDateValid(rest.value)
                          ? 'Date Time format “DD/MM/YYYY HH:MM”'
                          : error && error.message
                      }
                      required
                      error={error}
                      {...rest}
                      inputRef={ref}
                      disableFuture
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="progressionEndDate"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FdDateTimePicker
                      label="End Date and Time"
                      value={rest.value}
                      helperText={
                        rest.value && !isDateValid(rest.value)
                          ? 'Date Time format “DD/MM/YYYY HH:MM”'
                          : error && error.message
                      }
                      required
                      error={error}
                      {...rest}
                      inputRef={ref}
                      disableFuture
                    />
                  )}
                />
              </Box>
            </Box>
          </FdCard>
          <Box mb={2} height="610px">
            <FdTable
              defaultMuiToolbarSettings={{
                showMuiDefaultToolbar: true,
                filterButton: true,
                densityButton: false,
                columnsButton: false,
              }}
              toolbarSettings={{
                title: '',
                searchBox: true,
              }}
              actions={actions}
              rows={rows || []}
              columns={columns}
              pagination
              visibleSelection
              rowsPerPageOptions={[5, 10, 20]}
              tablePageSize={5}
              columnHeaderHeight={100}
              expandColumnHeaderTitle
              density="comfortable"
              gridId="assessor-proficiency-approach-table"
              {...props}
            />
          </Box>
        </FormProvider>
      </FdSkeleton>
      <FdModal
        size="xl"
        title={selectedTask?.taskName}
        dismiss="Close"
        open={selectedTask}
        onDismiss={() => setSelectedTask(undefined)}
        showConfirm={false}
        data-cy="task-details-modal"
      >
        <Summary
          data={[
            {
              value: <EventTile progression={selectedTask?.progression} />,
            },
            {
              value: selectedTask?.attemptsCount,
              description: 'Attempts',
            },
            {
              value: selectedTask?.specialty?.name,
              description: 'Specialty',
            },
            {
              value: (
                <Box
                  className="rounded px-2 py-1 my-1"
                  style={{
                    backgroundColor:
                      PROFICIENCY[PROFICIENCY_LEVELS[selectedTask?.difficulty]]
                        ?.color,
                    fontSize: '12px',
                    width: 'fit-content',
                  }}
                >
                  <FdTypography variant="captiontext1" color="white">
                    {PROFICIENCY_LEVELS[selectedTask?.difficulty]}
                  </FdTypography>
                </Box>
              ),
              description: 'Proficiency',
            },
          ]}
          titleVariant="subtitle1"
          subtitleVariant="captiontext1"
        />
        <Box mt={1}>
          <Box display="flex">
            <Box mt={1}>
              <FdTypography variant="h4">
                {selectedTask?.skillsFormatted &&
                  `Skills (${selectedTask?.skillsFormatted?.length})`}
              </FdTypography>
              <FdTypography variant="body1">
                {selectedTask?.skillsFormatted?.length > 0
                  ? getCommaSeparated(
                      selectedTask?.skillsFormatted?.map(
                        (s) => `${s.name} (${s.alias})`,
                      ) || [''],
                    )
                  : ''}
              </FdTypography>
            </Box>
          </Box>
        </Box>
        <FDTechGraphs
          tasks={modalTasks}
          completedTasks={modalCompletedTasks}
          selectedUserIds={selectedUserIds}
          graphHeight="150px"
          isModalView
        />
        <Box my={1} className="flex items-center justify-center gap-x-8">
          <Box className="flex items-center gap-x-2">
            <span
              className="inline-block w-4 h-4 rounded"
              style={{ backgroundColor: 'rgb(53, 195, 161)' }}
            />
            <FdTypography variant="captiontext1">
              Technique / Technology achieved in challenge
            </FdTypography>
          </Box>
          <Box className="flex items-center gap-x-2">
            <span
              className="inline-block w-4 h-4 rounded"
              style={{ backgroundColor: 'rgb(189, 189, 189)' }}
            />
            <FdTypography variant="captiontext1">
              Technique / Technology not achieved in challenge
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
    </>
  );
};

FDProficiencyApproachTable.propTypes = {
  taskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taskAttemptsDetail: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startDateTime: PropTypes.string.isRequired,
  endDateTime: PropTypes.string.isRequired,
  tasks: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assessmentStatus: PropTypes.string.isRequired,
};

export default FDProficiencyApproachTable;
