import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider } from '@mui/material';
import { FdTypography, FdHighlightChip } from '@fifthdomain/fe-shared';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';

const TaskDrawerHeader = ({ activeTask }) => {
  return (
    <>
      <Box mb={2} className="flex gap-2">
        <FdHighlightChip text={activeTask?.difficultyLabel} condensed />
        <FdHighlightChip
          text={`${activeTask?.recommendedPoints} pts`}
          condensed
        />
      </Box>
      <Card variant="outlined" className="py-3">
        <Box className="flex w-full justify-around">
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">
              {activeTask?.specialty?.name}
            </FdTypography>
            <FdTypography variant="captiontext1" color="secondary">
              Specialty
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">
              {upperCaseFirstLetter(activeTask?.type)}
            </FdTypography>
            <FdTypography variant="captiontext1" color="secondary">
              Type
            </FdTypography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

TaskDrawerHeader.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    recommendedPoints: PropTypes.number,
    difficultyLabel: PropTypes.string,
  }).isRequired,
};

export default TaskDrawerHeader;
