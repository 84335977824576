import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { Box, Divider, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import NotesIcon from '@mui/icons-material/Notes';
import {
  FdTable,
  FdSkeleton,
  FdButton,
  FdTypography,
  FdTooltip,
  FdCard,
  FdModal,
} from '@fifthdomain/fe-shared';
import {
  getCommaSeparated,
  getCommaSeparatedPlusSuffix,
} from '../../../shared/utils/stringUtils';
import {
  formatMinutes,
  sortByDateField,
} from '../../../shared/utils/dateUtils';
import {
  getDifficultyLabel,
  getDifficultyLabelColor,
} from '../../../shared/utils/difficultyMapping';
import Summary from '../Summary';
import TechGraphs from './TechGraphs';

const InfoIcon = () => (
  <IconButton size="small" style={{ marginLeft: '3px', marginBottom: '5px' }}>
    <InfoOutlinedIcon />
  </IconButton>
);
const ProficiencyLabel = ({ label, tooltip, indicator }) => (
  <Box className="flex items-center">
    <Box mr={1}>{indicator}</Box>
    <FdTypography variant="body2">{label}</FdTypography>
    <FdTooltip title={tooltip}>
      <span>
        <InfoIcon />
      </span>
    </FdTooltip>
  </Box>
);
ProficiencyLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
  indicator: PropTypes.node.isRequired,
};

const EventIndicator = ({ eventText }) => {
  const color = { O: '#2196F3', S: '#8E24AA', A: '#EF5350' };
  if (eventText === 'star') {
    return (
      <Box
        className="rounded-full h-5 w-5 flex items-center justify-center"
        style={{ backgroundColor: '#4CAF50' }}
      >
        <StarIcon className="h-6 w-6 fill-current text-white p-1" />
      </Box>
    );
  }
  return (
    <Box
      style={{
        backgroundColor: color[eventText],
        borderLeft: '1px solid #fff',
      }}
      className="h-4 w-4 flex align-center justify-center rounded-sm"
    >
      <Box className="text-white text-xs">{eventText}</Box>
    </Box>
  );
};
EventIndicator.propTypes = {
  eventText: PropTypes.string.isRequired,
};

const eventMap = { O: 'Opened', S: 'Started', A: 'Attempted', star: 'Solved' };
const EventTile = ({ progression }) => {
  const progressionSorted = progression?.sort(
    (a, b) =>
      Object.keys(eventMap).indexOf(a.event) -
      Object.keys(eventMap).indexOf(b.event),
  );
  const event =
    progressionSorted?.length > 0
      ? progressionSorted[progressionSorted?.length - 1]?.event
      : '';
  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center">
        <EventIndicator eventText={event} />
        <Box ml={1}>{eventMap[event]}</Box>
      </Box>
      {event !== 'star' && (
        <FdTypography variant="captiontext1" color="secondary">
          Not Solved
        </FdTypography>
      )}
    </Box>
  );
};
EventTile.propTypes = {
  progression: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const getTimeInPercentage = (_dateTime, _totalMinutes, _minDateTime) => {
  const givenTimeInMinutes = differenceInMinutes(
    new Date(_dateTime),
    new Date(_minDateTime),
  );
  return (givenTimeInMinutes / _totalMinutes) * 100;
};

const ProficiencyApproachTable = ({
  taskAttempts,
  taskAttemptsDetail,
  startDateTime,
  loading,
  taskNotes,
  tasks,
  completedTasks,
  selectedUserIds,
  teamBased,
  tasksOpened,
  timeSpentInMins,
  ...props
}) => {
  const [selectedTask, setSelectedTask] = useState(undefined);
  const allEventRows = [
    ...taskAttemptsDetail.map((ta) => ({
      taskId: ta?.task?.id,
      event: ta?.success ? 'star' : 'A',
      dateTime: ta?.createdAt,
    })),
    ..._(tasksOpened.filter((t) => selectedUserIds.includes(t.userId)))
      .groupBy('taskOpenedTaskId')
      .map((_tasks, taskId) => ({
        taskId,
        event: 'O',
        dateTime: _.minBy(_tasks, 'createdAt').createdAt,
      }))
      .value(),
    ...tasksOpened
      .filter((to) => selectedUserIds.includes(to.userId) && to?.startedSolving)
      .map((to) => ({
        taskId: to?.taskOpenedTaskId,
        event: 'S',
        dateTime: to?.startedSolvingAt,
      })),
  ];

  const progressionData = allEventRows.sort(sortByDateField('dateTime'));
  const maxDurationInMins = timeSpentInMins || 0;
  const maxDateTime = addMinutes(new Date(startDateTime), maxDurationInMins);
  const minDateTime = startDateTime;
  const totalMinutes =
    differenceInMinutes(new Date(maxDateTime), new Date(minDateTime)) + 5;

  const timeLinePoints = Math.round(totalMinutes / 20);
  const progressionDataForGraph = progressionData?.map((pd) => ({
    ...pd,
    position: getTimeInPercentage(pd.dateTime, totalMinutes, minDateTime),
    givenTime: differenceInMinutes(
      new Date(pd.dateTime),
      new Date(minDateTime),
    ),
  }));

  const columns = [
    {
      field: 'taskName',
      width: 350,
      headerName: 'Challenge Name',
      renderCell: (params) => {
        return (
          <Box>
            <FdTypography variant="subtitle2">{params.value}</FdTypography>
            <Box
              className="rounded px-1 p-py my-1"
              style={{
                backgroundColor: getDifficultyLabelColor(params.row.difficulty),
                fontSize: '10px',
                width: 'fit-content',
              }}
            >
              {getDifficultyLabel(params.row.difficulty)}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'skills',
      width: 190,
      headerName: 'Skills',
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Skills</FdTypography>
            <FdTooltip title="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort.">
              <span>
                <InfoIcon />
              </span>
            </FdTooltip>
          </Box>
        );
      },
      valueGetter: (params) =>
        params?.value?.length > 0 ? [...params?.value]?.join(' ') : '',
      renderCell: (params) => getCommaSeparatedPlusSuffix(params?.row?.skills),
    },
    {
      field: 'progression',
      headerName: 'Progression',
      filterable: false,
      unSort: true,
      flex: 2,
      renderHeader: () => {
        // set timeline legend
        let minutesInterval = 0;
        const timeLine = [];
        const timeLineSplit = Math.round(100 / timeLinePoints);
        const timeLineLimit = timeLinePoints === 0 ? 1 : timeLinePoints; // show at least one point
        for (let i = 0; i < timeLineLimit; i += 1) {
          timeLine.push({
            value: i === 0 ? '0m' : formatMinutes(minutesInterval, true, true),
            position: timeLineSplit * i,
          });
          minutesInterval += 20;
        }

        return (
          <Box className="w-full mt-1 pr-5">
            <Box className="flex items-center mt-1 w-full justify-between">
              <Box>
                <FdTypography variant="subtitle2">Progression</FdTypography>
              </Box>
              <FdCard
                variant="outlined"
                className="flex items-center h-11 ml-6 w-100"
              >
                <Box className="flex gap-x-6 h-8">
                  <ProficiencyLabel
                    label="Open"
                    tooltip="A 'Open' event is when a participant opens a challenge and views its basic details."
                    indicator={<EventIndicator eventText="O" />}
                  />
                  <ProficiencyLabel
                    label="Start"
                    tooltip="After having viewed the basic details, a 'Start' event occurs when a participant engages further transitioning into the expanded challenge view. The expanded challenge view encompasses attachments, lab environments, and hints."
                    indicator={<EventIndicator eventText="S" />}
                  />
                  <ProficiencyLabel
                    label="Attempt"
                    tooltip="An 'Attempt' is logged when a participant submits their response or FLAG for the challenge. Participants have the flexibility to make multiple attempts according to their preference. Nonetheless, their efficiency rating gradually diminishes with each subsequent attempt."
                    indicator={<EventIndicator eventText="A" />}
                  />
                  <ProficiencyLabel
                    label="Solved"
                    tooltip="A challenge is marked 'Solved' when the participant enters the correct FLAG."
                    indicator={<EventIndicator eventText="star" />}
                  />
                </Box>
              </FdCard>
            </Box>
            <Box className="w-full h-10 relative flex items-center bg-grey-1">
              {timeLine.map((tl) => (
                <Box
                  className="flex items-center absolute h-full"
                  style={{
                    top: 0,
                    left: `${tl.position}%`,
                  }}
                >
                  <FdTypography variant="captiontext1">{tl.value}</FdTypography>
                </Box>
              ))}
            </Box>
          </Box>
        );
      },
      renderCell: (params) => {
        const progression = params.value;
        const starIndex = progression.findIndex((p) => p.event === 'star');
        const progressionFiltered =
          starIndex !== -1 ? progression?.slice(0, starIndex + 1) : progression;

        return (
          <Box className="w-full pr-5">
            {progressionFiltered?.map((p) => (
              <Box className="w-full h-full relative pl-2">
                <Box
                  className="flex items-center absolute h-full"
                  style={{
                    top: 0,
                    left: `${p.position}%`,
                  }}
                >
                  <FdTooltip title={p.givenTime}>
                    <span>
                      <EventIndicator eventText={p.event} />
                    </span>
                  </FdTooltip>
                </Box>
              </Box>
            ))}
          </Box>
        );
      },
    },
  ];
  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        return (
          <FdButton
            variant="tertiary"
            size="small"
            onClick={() => setSelectedTask(rowData)}
          >
            View
          </FdButton>
        );
      },
    },
  ];

  const rows = _([
    ...taskAttempts,
    ...tasksOpened.map((to) => ({ ...to, taskId: to.taskOpenedTaskId })),
  ])
    .groupBy('taskId')
    .map((items, taskId) => {
      const { task } = items[0] ?? {};
      return {
        ...task,
        id: taskId,
        taskName: task?.name,
        difficulty: task?.difficulty,
        skills: task?.skills?.items.map(({ skill }) => skill?.name),
        progression: progressionDataForGraph
          .filter((pg) => pg.taskId === taskId)
          .map((pgd) => ({
            position: pgd.position,
            event: pgd.event,
            givenTime:
              pgd.givenTime === 0
                ? '0m'
                : formatMinutes(pgd.givenTime, true, true),
          })),
        skillsFormatted: task?.skills?.items.map(({ skill }) => ({
          name: skill?.name,
          alias: skill?.alias,
        })),
        notes: taskNotes?.filter((tn) => tn.taskId === taskId)[0]?.notes || '', // show first note
        attemptsCount:
          taskAttemptsDetail?.filter((ta) => ta.task?.id === taskId)?.length ||
          0,
      };
    })
    .filter((t) => t.taskName)
    .value();

  return (
    <>
      <FdSkeleton loading={loading} height="532px">
        <Box mt={2} mb={2} height="500px">
          <FdTable
            defaultMuiToolbarSettings={{
              showMuiDefaultToolbar: true,
              filterButton: true,
              densityButton: false,
              columnsButton: false,
            }}
            toolbarSettings={{
              title: '',
              searchBox: true,
            }}
            actions={actions}
            rows={rows || []}
            columns={columns}
            pagination
            visibleSelection
            rowsPerPageOptions={[5, 10, 20]}
            tablePageSize={5}
            columnHeaderHeight={100}
            gridId="assessor-proficiency-approach-table"
            {...props}
          />
        </Box>
      </FdSkeleton>
      <FdModal
        size="lg"
        title={selectedTask?.taskName}
        dismiss="Close"
        open={selectedTask}
        onDismiss={() => setSelectedTask(undefined)}
        showConfirm={false}
        data-cy="task-details-modal"
      >
        <Summary
          data={[
            {
              value: <EventTile progression={selectedTask?.progression} />,
            },
            {
              value: selectedTask?.attemptsCount,
              description: 'Attempts',
            },
            {
              value: selectedTask?.specialty?.name,
              description: 'Specialty',
            },
            {
              value: getDifficultyLabel(selectedTask?.difficulty),
              description: 'Proficiency',
            },
          ]}
          titleVariant="subtitle1"
          subtitleVariant="captiontext1"
        />
        <Box mt={1}>
          <FdTypography variant="h4" className="py-5">
            Challenge Details
          </FdTypography>
          <Box display="flex">
            <Divider orientation="vertical" flexItem />
            <Box ml={2}>
              <FdTypography variant="subtitle1">Summary</FdTypography>
              <FdTypography variant="body1" color="secondary">
                {selectedTask?.summary}
              </FdTypography>
              <Box mt={1}>
                <FdTypography variant="subtitle1">
                  {selectedTask?.skillsFormatted &&
                    `Skills included (${selectedTask?.skillsFormatted?.length})`}
                </FdTypography>
                <FdTypography variant="body1">
                  {getCommaSeparated(
                    selectedTask?.skillsFormatted?.map(
                      (s) => `${s.name} (${s.alias})`,
                    ) || [''],
                  )}
                </FdTypography>
              </Box>
              <Box minHeight="250px" style={{ overflowY: 'auto' }}>
                <TechGraphs
                  loading={false}
                  tasks={{
                    items: tasks?.items?.filter(
                      (t) => t.taskId === selectedTask?.id,
                    ),
                  }}
                  completedTasks={completedTasks?.filter(
                    (t) => t.taskId === selectedTask?.id,
                  )}
                  selectedUserIds={selectedUserIds}
                  tasksInAssessment={tasks}
                  containerHeight="430px"
                  graphHeight="300px"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <NotesIcon className="mr-3" />
          <FdTypography variant="h4" className="py-5">
            {`${teamBased ? "Team's" : "Participant's"} Notes`}
          </FdTypography>
        </Box>
        <Box display="flex">
          <Divider orientation="vertical" flexItem />
          <Box ml={2}>
            <FdTypography variant="body1">
              {selectedTask?.notes || '-'}
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
    </>
  );
};

ProficiencyApproachTable.propTypes = {
  taskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taskAttemptsDetail: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  startDateTime: PropTypes.string.isRequired,
  taskNotes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasks: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamBased: PropTypes.bool.isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeSpentInMins: PropTypes.string.isRequired,
};

ProficiencyApproachTable.defaultProps = {
  loading: false,
};

export default ProficiencyApproachTable;
