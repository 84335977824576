import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  FdButton,
  FdModal,
  FdCard,
  FdTypography,
  FdTooltip,
  useSnapshot,
  globalStore,
  successToastMessage,
  FdHighlightChip,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql } from '@apollo/client';
import { propTypes } from 'react-markdown';
import { BarNivo } from '../Charts';
import HeatmapNivo from '../Charts/HeatmapNivo';
import {
  getAssessmentChallengesTechniques,
  getAssessmentChallengesTechnologies,
  getSkillsProficienciesMappedData,
} from '../../shared/utils/getAssessmentOverview';
import SummaryTitle from '../Assessment/SummaryTitle';
import AssessmentTemplateTable from './AssessmentTemplateTable';
import ViewTaskDrawer from '../Assessment/ViewTaskDrawer';
import AssessmentTemplateDetails from './AssessmentTemplateDetails';
import { validationSchema } from '../../validation-schemas/TemplateAssessment';
import { createModulePart, createNewAssessment } from '../../graphql/mutations';
import { PROFICIENCY, proficiencyLevels } from '../../constants';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import HeatMapCustomLegend from '../Assessment/HeatMapCustomLegend';
import useGetSvgWidthOnResize from '../../hooks/useGetSvgWidthOnResize';
import { getSpecialtyLegends } from '../Assessment/getSpecialtyLegends';

const AssessmentTemplate = ({ selectedTemplate, specialtySkills }) => {
  const globalSnap = useSnapshot(globalStore);
  const [divRef, width, labelWidth, updateHeatMapWidth] =
    useGetSvgWidthOnResize();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [generateAssessmentModal, setGenerateAssessmentModal] = useState(false);
  const drawerWidth = 400;

  const reactHookFormMethods = useForm({
    defaultValues: selectedTemplate,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { reset, getValues, trigger } = reactHookFormMethods;

  useEffect(() => {
    // update heatmap label
    setTimeout(() => {
      updateHeatMapWidth();
    }, 3000);
  }, [updateHeatMapWidth]);

  const [createNewAssessmentMutation, { loading: createLoading }] = useMutation(
    gql(createNewAssessment),
  );

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  const tableData = selectedTemplate?.tasks?.items.map((t) => ({
    ...t.task,
    id: t.id,
    taskId: t?.task?.id,
    labId: t?.task?.labId,
    difficultyInteger: t?.task?.difficulty,
    difficultyLabel:
      t?.task?.difficulty > 5
        ? getDifficultyLabel(t?.task?.difficulty)
        : proficiencyLevels.at(t?.task?.difficulty - 1),
    specialtyName: t?.task?.specialty?.name,
    skills: t?.task?.skills?.items.map((s) => s?.skill?.name),
    techniqueTags: t?.task?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: t?.task?.technologyTags?.items.map(
      (tt) => tt.technologyTag?.name,
    ),
  }));

  const assessmentChallengesTechniques = getAssessmentChallengesTechniques(
    selectedTemplate?.tasks?.items.map((t) => t?.task),
  );

  const assessmentChallengesTechnologies = getAssessmentChallengesTechnologies(
    selectedTemplate?.tasks?.items.map((t) => t?.task),
  );

  const labBasedSelectedTasks = tableData
    ?.filter((t) => t?.type === 'LAB')
    ?.map((labBasedTask) => ({
      ...labBasedTask,
      id: labBasedTask?.taskId,
      difficulty: getDifficultyLabel(labBasedTask.difficulty),
      status: 'Undefined',
    }));

  const watchTasks = tableData?.map((item) => item?.taskId);

  const skillsProficienciesByProficiency = _.groupBy(
    selectedTemplate?.tasks?.items
      .map((t) => t?.task)
      ?.map((task) => ({
        proficiency: task.difficulty,
        skills: task.skills.items.map((_skill) => ({
          name: _skill.skill.name,
          alias: _skill.skill.alias,
        })),
      })),
    'proficiency',
  );

  const { specialtyLegends, skillsSorted } = getSpecialtyLegends({
    specialtySkills,
  });

  const skillsProficienciesData = getSkillsProficienciesMappedData(
    skillsProficienciesByProficiency,
    skillsSorted,
  );

  const Tile = ({ value, description }) => (
    <Box pr={2}>
      <SummaryTitle
        data={{ value, description }}
        titleVariant="subtitle2"
        subtitleVariant="h4"
        justifyContent="center"
        subtitleColor="primary"
      />
    </Box>
  );
  Tile.propTypes = {
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  const templateOverview = [
    {
      value: 'Total Challenges',
      description: selectedTemplate?.tasks?.items?.length,
    },
    {
      value: 'Duration',
      description: selectedTemplate?.duration,
    },
  ];

  const rowDivider = templateOverview?.length - 1;
  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  const TechniquesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Techniques in the assessment
        </FdTypography>
        <FdTooltip title="Techniques refer to the specific methodologies or strategies required to resolve a challenge, such as Cipher Decryption, Image Analysis, etc.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        All the techniques in this assessment template are listed below. The
        length of the bar represents the weightage of that technique in the
        assessment, and the number of segments on the bar represent the number
        of challenges the technique is a part of. You can hover over the bar
        segments to see the challenge name.
      </FdTypography>
      <Box height="450px">
        <BarNivo
          data={assessmentChallengesTechniques}
          keys={tableData.map((t) => t.name)}
          indexBy="technique"
          colors={['#35C3A1']}
          layout="horizontal"
          borderWidth={3}
          borderColor="#fff"
          enableLabel={false}
          tooltip={(d) => (
            <Box
              style={{
                backgroundColor: '#777',
                color: '#fff',
                padding: '4px 8px',
                fontSize: '12px',
              }}
            >
              {d.id}
            </Box>
          )}
          labelTextColor="#000"
        />
      </Box>
    </Box>
  );

  const TechnologiesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Technologies in the assessment
        </FdTypography>
        <FdTooltip title="Technologies include the tools or environments incorporated within the challenge. They can be the environment itself, such as Windows, Linux, or Docker, or tools utilized within those environments, such as Splunk, Nessus, Python, etc. These technologies form a crucial part of the challenge, offering resources to navigate and solve the challenge effectively.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        All the technologies in this assessment are listed below. The length of
        the bar represents the weightage of that technology in the assessment,
        and the number of segments on the bar represent the number of challenges
        the technique is a part of. You can hover over the bar segments to see
        the challenge name.
      </FdTypography>
      <Box height="450px">
        <BarNivo
          data={assessmentChallengesTechnologies}
          keys={tableData.map((t) => t.name)}
          indexBy="technology"
          colors={['#35C3A1']}
          layout="horizontal"
          borderWidth={3}
          borderColor="#fff"
          enableLabel={false}
          tooltip={(d) => (
            <Box
              style={{
                backgroundColor: '#777',
                color: '#fff',
                padding: '4px 8px',
                fontSize: '12px',
              }}
            >
              {d.id}
            </Box>
          )}
          labelTextColor="#000"
        />
      </Box>
    </Box>
  );
  const loading = createLoading || createModulePartLoading;

  return (
    <>
      <Box display="flex" justifyContent="space-between" my={2}>
        <FdPageHeading type="ASSESSMENT" variant="h2">
          {selectedTemplate?.name}
        </FdPageHeading>
        <FdButton
          disabled={loading}
          size="large"
          onClick={(_value) => {
            setGenerateAssessmentModal(true);
          }}
        >
          GET THIS template
        </FdButton>
      </Box>
      <FdCard variant="outlined">
        <Box display="flex" justifyContent="space-evenly">
          {templateOverview?.map((t, idx) => (
            <>
              <Tile value={t.value} description={t.description} />
              {idx < rowDivider && <Divider orientation="vertical" />}
            </>
          ))}
        </Box>
      </FdCard>

      <FdCard variant="outlined">
        <Box>
          <Box display="flex" flexDirection="column" flexGrow={1} pr={2}>
            <FdTypography variant="h4">
              What does this assessment cover?
            </FdTypography>
            <FdTypography
              variant="body2"
              color="secondary"
              style={{ margin: '16px 0' }}
            >
              This heatmap shows the skills that are being tested in this
              assessment in relation to the proficiency level. The X-Axis shows
              the skills and Y-axis represents the Proficiency level . Together,
              the blue-grey boxes show which skill of what proficiency has been
              covered in this assessment. Hover over the blue-grey boxes to see
              these details. You can also hover over the skills abbreviations on
              x-axis to see the skill name.
            </FdTypography>
            <Box style={{ height: '410px' }}>
              <Box ref={divRef} height="100%">
                <HeatmapNivo
                  customTopLegend={
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="captiontext1">
                        Proficiency Levels
                      </FdTypography>

                      {Object.keys(PROFICIENCY).map((proficiency) => (
                        <Box p={1} display="flex" alignItems="center">
                          <FdTypography variant="captiontext1">{` ${PROFICIENCY[proficiency].level}:`}</FdTypography>
                          <Box ml={0.5}>
                            <FdHighlightChip text={proficiency} />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  }
                  customBottomLegend={
                    <HeatMapCustomLegend
                      width={width}
                      labelWidth={labelWidth}
                      specialtyLegends={specialtyLegends}
                    />
                  }
                  data={
                    skillsProficienciesData?.sort((a, b) => b.id - a.id) || []
                  }
                  yAxisLabel="Proficiency Levels"
                  tooltip={({ cell }) => {
                    return (
                      <Box
                        style={{
                          backgroundColor: '#777',
                          color: '#fff',
                          padding: '4px 8px',
                          fontSize: '12px',
                        }}
                      >
                        <FdTypography variant="captiontext2">
                          {cell.data?.skill}
                        </FdTypography>
                        <FdTypography variant="captiontext2">
                          {` Proficiency: ${cell.data?.proficiency}`}
                        </FdTypography>
                      </Box>
                    );
                  }}
                  color="#3E3EB7"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex xs:flex-wrap space-x-4">
          <TechniquesTab />
          <TechnologiesTab />
        </Box>
      </FdCard>

      <Grid container>
        <Grid item xs>
          <AssessmentTemplateTable rows={tableData} actions={viewTaskActions} />
        </Grid>
        <Grid
          item
          style={{
            width: openDrawer ? drawerWidth : 0,
          }}
        />
      </Grid>
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'assessor']}
          templateViewDrawer
        />
      )}
      <FdModal
        title="Great! Your assessment is almost ready."
        description="We need a few important details to create this assessment. If you wish to change these, you can always edit them later."
        confirm="Generate Assessment"
        dismiss="CANCEL"
        open={generateAssessmentModal}
        disableConfirm={loading}
        onConfirm={async () => {
          // Mutation to create assessment
          const result = await trigger();
          if (result) {
            const { name, startDateTime, endDateTime } = getValues();
            const hasLabBasedTasks = labBasedSelectedTasks?.length > 0;
            // create module parts for lab based tasks
            const modulePartsPromises = labBasedSelectedTasks.map(
              (labBasedTask, index) => {
                const {
                  name: _name,
                  description,
                  labId,
                  id: taskId,
                } = labBasedTask;

                return createModulePartMutation({
                  variables: {
                    input: {
                      courseModuleId: taskId,
                      description,
                      name: _name,
                      orderNumber: index + 1,
                      type: 'LAB',
                      labId,
                    },
                  },
                });
              },
            );
            Promise.all(modulePartsPromises).then((modulePartsData) => {
              const modulePartIds = modulePartsData.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.data.createModulePart.courseModuleId]:
                    curr.data.createModulePart.id,
                }),
                {},
              );
              createNewAssessmentMutation({
                variables: {
                  assesssment: {
                    description: selectedTemplate?.description,
                    endDateTime,
                    hours: selectedTemplate?.hours,
                    minutes: selectedTemplate?.minutes,
                    name,
                    orgId: globalSnap.orgId,
                    participantEventType: 'ASSESSMENT',
                    postMessage: selectedTemplate?.postMessage,
                    preMessage: selectedTemplate?.preMessage,
                    teamBased: selectedTemplate?.teamBased,
                    startDateTime,
                    status: 'READY',
                    taskIds: hasLabBasedTasks ? [] : watchTasks,
                    templateId: selectedTemplate?.id,
                    taskLabs: hasLabBasedTasks
                      ? watchTasks.map((taskId) => ({
                          taskId,
                          modulePartId: modulePartIds?.[taskId],
                        }))
                      : [],
                  },
                },
                onCompleted: (data) => {
                  const assessmentId = data?.createNewAssessment;
                  successToastMessage(
                    'Your assessment has been successfully created',
                  );
                  reset();
                  singleSpa.navigateToUrl(
                    // tabindex=2, load on participant or teams tab
                    `/assessor/view/${assessmentId}?tabindex=2`,
                  );
                },
              });
            });

            setGenerateAssessmentModal(false);
          }
        }}
        onDismiss={() => {
          reset();
          setGenerateAssessmentModal(false);
        }}
      >
        <FormProvider {...reactHookFormMethods}>
          <form>
            <AssessmentTemplateDetails />
          </form>
        </FormProvider>
      </FdModal>
    </>
  );
};

AssessmentTemplate.propTypes = {
  selectedTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    assessmentType: PropTypes.string,
    duration: PropTypes.string,
    description: PropTypes.string,
    preMessage: PropTypes.string,
    postMessage: PropTypes.string,
    hours: propTypes.string,
    minutes: PropTypes.string,
    teamBased: PropTypes.bool,
    tasks: PropTypes.arrayOf({
      items: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }).isRequired,
  specialtySkills: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default AssessmentTemplate;
