import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const Donut = ({ data, labels, colors, chartId, enableAnimations }) => {
  const options = {
    series: data,
    options: {
      plotOptions: {
        donut: {
          expandOnClick: false,
        },
      },
      labels,
      legend: {
        show: true,
        position: 'bottom',
      },
      chart: {
        id: chartId,
        redrawOnParentResize: true,
      },
      animations: {
        enabled: enableAnimations,
      },
    },
  };
  if (colors) {
    options.colors = colors;
  }

  return (
    <Box data-cy={`donut-chart-${chartId}`}>
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
        width="700"
        height="350"
      />
    </Box>
  );
};

Donut.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  chartId: PropTypes.string,
  enableAnimations: PropTypes.bool,
};

Donut.defaultProps = {
  data: [],
  labels: [],
  colors: [],
  chartId: 'donut-chart',
  enableAnimations: true,
};

export default Donut;
