import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Stepper, Step, StepLabel, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql, useQuery } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  BasePageButtonContainer,
  FdLoadingSpinner,
  FdAlert,
  FdModal,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  warningToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { hoursToMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import ViewTaskDrawer from '../components/Assessment/ViewTaskDrawer';
import {
  Details,
  Availability,
  Duration,
  AssessmentOverview,
  TasksTable,
  PreMessage,
  PostMessage,
  Jumpbox,
} from '../components/Assessment';
import { createModulePart, createNewAssessment } from '../graphql/mutations';
import {
  getLabBasedTasks,
  getReleasedTemplatesChallenges,
} from '../shared/utils/taskUtils';
import scrollToTop from '../shared/utils/scroll';
import { ASSESSMENT_TYPES } from '../constants';
import useOrgId from '../hooks/useOrgId';
import {
  listTaskOrgs,
  listTasksByOrgId,
  getAssessment,
  queryTemplatesByStatus,
} from '../graphql/queries';
import { invalidateAdminHomePageDataQuery } from '../queries/invalidateQueries';
import { initialValues, validationSchema } from '../validation-schemas';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';
import { listSkills } from '../queries/customQueries';

const drawerWidth = 400;

const useStyles = makeStyles()(() => ({
  stepper: {
    background: 'none',
    paddingLeft: '5px',
    height: '50px',
  },
}));

const CreateAssessment = ({ formMode }) => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const userOrgIdData = useOrgId();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const isDuplicateMode = formMode === 'duplicate';
  const { _assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const isPricingTierStarter = globalSnap.orgPricingTier === 'STARTER';

  const steps = ['Details', 'Challenges', 'Messaging'];

  const [createNewAssessmentMutation, { loading: createLoading }] = useMutation(
    gql(createNewAssessment),
  );

  const { data: allTasksData, loading: allTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap.orgId,
        filter: {
          status: { eq: 'APPROVED' },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !globalSnap.orgId,
      onCompleted: () => scrollToTop(),
    },
  );

  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableToOrgLoading,
    refetch: allTasksAvailableRefetch,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: userOrgIdData?.userData?.getUserById?.items[0].orgId,
    },
    skip: !userOrgIdData,
  });

  const { data: releasedTemplates, loading: templatesLoading } =
    useQueryRecursive(gql(queryTemplatesByStatus), {
      variables: {
        status: 'RELEASED',
      },
    });

  const { data: listSkillsData } = useQueryRecursive(gql(listSkills));

  const specialtySkillsData = listSkillsData?.listSkills?.items || [];

  const allTasksMadeAvailable =
    allTasksAvailableToOrg?.listTaskOrgs?.items?.map(
      (taskOrg) => taskOrg?.task,
    );

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items,
        );

  const allTasks = _.uniqBy(
    [
      ...(allTasksData?.listTasksByOrgId?.items?.map((t) => ({
        ...t,
        owned: true,
      })) || []),
      ...(allTasksMadeAvailable?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const { classes } = useStyles();

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    handleSubmit,
  } = reactHookFormMethods;

  const watchTasks = watch('taskIds');

  // Get the assessment details for duplicate
  const { loading: assessmentDataLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: _assessmentId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isDuplicateMode,
    onCompleted: (data) => {
      if (!isDirty) {
        // if form is in changed state then do not refresh the whole form,
        // instead each block actions reset values based on action
        const {
          description,
          hours,
          minutes,
          preMessage,
          postMessage,
          videoUrl,
        } = data?.getAssessment;
        reset({
          assessmentType: {
            id: 'assessmentType',
            value: ASSESSMENT_TYPES.INDIVIDUAL_BASED,
          },
          name: `Copy of ${data.getAssessment?.name}`,
          description,
          startDateTime: null,
          endDateTime: null,
          hours,
          minutes,
          preMessage,
          postMessage,
          videoUrl,
          taskIds: data.getAssessment?.tasks.items.map((task) => task.taskId),
          labControls: data.getAssessment.tasks.items
            ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
            .reduce((acc, curr) => {
              const {
                duration,
                inactivityExpiry,
                initialLabCount,
                minLabCount,
              } = curr?.modulePart || {
                duration: hoursToMinutes(Number(hours)) + Number(minutes),
                inactivityExpiry: 14,
                initialLabCount: 0,
                minLabCount: 0,
              };
              return {
                ...acc,
                [curr.taskId]: {
                  labControlDefined: true,
                  labDuration: duration,
                  labAbandonment: inactivityExpiry,
                  initialLabPool: initialLabCount,
                  minimumLabPool: minLabCount,
                },
              };
            }, {}),
          taskLabs: data.getAssessment.tasks.items
            ?.filter((assessmentTask) => assessmentTask.task.type === 'LAB')
            .reduce((acc, curr) => {
              return {
                ...acc,
                [curr.taskId]: curr.modulePartId,
              };
            }, {}),
        });
      }
    },
  });
  const labControls = watch('labControls');

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  if (allTasksLoading || templatesLoading || assessmentDataLoading) {
    return <FdLoadingSpinner />;
  }

  const tableData = allTasks?.map((task) => ({
    ...task,
    difficultyInteger: task?.difficulty,
    difficultyLabel: getDifficultyLabel(task?.difficulty),
    specialtyName: task?.specialty?.name,
    skills: task?.skills?.items.map((s) => s.skill?.name),
    techniqueTags: task?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: task?.technologyTags?.items.map(
      (t) => t.technologyTag?.name,
    ),
    specialtyAreas: [
      ...new Set(
        task.competencies?.items
          ?.map((competencyData) => competencyData?.area?.areaName)
          .flat(),
      ),
    ],
    creator: task?.user?.name,
    ownerOrg: task?.org?.name,
    ownerOrgId: task?.org?.id,
    creatorOrg: task?.user?.org?.name,
    creatorOrgId: task?.user?.org?.id,
    tags:
      task?.tags?.items?.filter((t1) => t1?.Tag?.orgId === globalSnap?.orgId) ||
      [],
  }));

  const labBasedSelectedTasks = getLabBasedTasks(allTasks, watchTasks).map(
    (labBasedTask) => ({
      ...labBasedTask,
      difficultyLabel: getDifficultyLabel(labBasedTask?.difficulty),
      specialtyName: labBasedTask?.specialty?.name,
      skills: labBasedTask?.skills?.items.map((s) => s.skill?.name),
      status:
        labControls?.[labBasedTask?.id] &&
        labControls?.[labBasedTask?.id]?.labControlDefined
          ? 'Defined'
          : 'Undefined',
    }),
  );

  const onSubmit = async () => {
    const values = getValues();
    const teamBased =
      values.assessmentType.value === ASSESSMENT_TYPES.TEAM_BASED;

    const { id, orgId } = userOrgIdData?.userData?.getUserById?.items[0];

    const isDev = document.location.host === 'localhost:5000';

    // segment track event
    /* eslint-disable no-undef */
    if (!isDev) {
      if (analytics) {
        analytics.track('ASSESSMENT_CREATED', {
          userId: id,
          orgId,
        });
      }
    }

    const hasLabBasedTasks = labBasedSelectedTasks.length > 0;

    // create module parts for lab based tasks
    const modulePartsPromises = labBasedSelectedTasks.map(
      (labBasedTask, index) => {
        const { name, description, labId, id: taskId } = labBasedTask;
        return createModulePartMutation({
          variables: {
            input: {
              courseModuleId: taskId,
              description,
              name,
              orderNumber: index + 1,
              type: 'LAB',
              labId,
            },
          },
        });
      },
    );

    const newMutationValues = { ...values };

    delete newMutationValues.duration;
    delete newMutationValues.assessmentType;
    delete newMutationValues.labControls;
    delete newMutationValues.jumpbox;
    delete newMutationValues.enableVPN;

    Promise.all(modulePartsPromises).then((modulePartsData) => {
      const modulePartIds = modulePartsData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.data.createModulePart.courseModuleId]:
            curr.data.createModulePart.id,
        }),
        {},
      );
      createNewAssessmentMutation({
        variables: {
          assesssment: {
            ...newMutationValues,
            status: 'READY',
            teamBased,
            enableJumpbox:
              values?.jumpbox?.toString()?.toUpperCase() || 'FALSE',
            enableVPN: values?.enableVPN?.toString()?.toUpperCase() || 'FALSE',
            orgId,
            participantEventType: 'ASSESSMENT',
            taskIds: hasLabBasedTasks ? [] : watchTasks,
            taskLabs: hasLabBasedTasks
              ? watchTasks.map((taskId) => ({
                  taskId,
                  modulePartId: modulePartIds?.[taskId],
                }))
              : [],
          },
        },
        onCompleted: (data) => {
          const assessmentId = data?.createNewAssessment;
          successToastMessage(
            isDuplicateMode
              ? 'Success! Duplicate Assessment created'
              : 'Your assessment has been successfully created',
          );
          // eslint-disable-next-line no-use-before-define
          reset();
          invalidateAdminHomePageDataQuery();
          setTimeout(() => {
            singleSpa.navigateToUrl(
              // tabindex=2, load on participant or teams tab
              `/assessor/view/${assessmentId}?tabindex=2`,
            );
          }, 500);
        },
      });
    });
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        // set touched to enable validation for duration
        setValue('hours', String(getValues('hours')), { shouldTouch: true });
        result = await trigger([
          'assessmentType',
          'name',
          'description',
          'startDateTime',
          'endDateTime',
          'hours',
          'minutes',
          'duration',
        ]);
        break;
      }
      case 1:
        result = await trigger(['taskIds']);
        break;
      case 2:
        result = await trigger(['preMessage', 'postMessage']);
        break;
      default:
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 2) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  if (isPricingTierStarter) {
    singleSpa.navigateToUrl('/assessor/create/template');
  }

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Create Assessment', type: 'ASSESSMENT' }}
      />
      <BasePage
        type="ASSESSMENT"
        heading={`${isDuplicateMode ? 'Duplicate' : 'Create'} Assessment`}
        data-cy="create-assessment-base-page"
      >
        <Box width="734px">
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            data-cy="stepper"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel data-cy="stepper-label">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              {
                0: (
                  <Box>
                    <Details isDuplicateMode={isDuplicateMode} />
                    <Jumpbox />
                    <Availability isDuplicateMode={isDuplicateMode} />
                    <Duration />
                  </Box>
                ),
                1: (
                  <>
                    <Box>
                      <Grid container>
                        <Grid item xs>
                          {isDuplicateMode && (
                            <Box mb={2}>
                              <FdCard
                                heading="Challenges"
                                subHeading="Each challenge from the the assessment to be duplicated is listed in the table below. Use the checkbox column to select which challenges should be included in the duplicate assessment to be created. All accompanying challenge content (e.g. labs, static files.) will be copied alongside the duplicate assessment created. "
                              />
                            </Box>
                          )}
                          <AssessmentOverview
                            allTasks={allTasks}
                            tasks={watchTasks}
                            loading={allTasksAvailableToOrgLoading}
                            hours={getValues('hours')}
                            minutes={getValues('minutes')}
                            specialtySkills={specialtySkillsData}
                          />
                          <TasksTable
                            data={tableData}
                            viewTaskActions={viewTaskActions}
                            isRefreshLoading={allTasksAvailableToOrgLoading}
                            onRefreshTasks={() => allTasksAvailableRefetch()}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            width: openDrawer ? drawerWidth : 0,
                          }}
                        />
                      </Grid>
                      {errors.taskIds && (
                        <Box mt={2} mb={2} data-cy="error-alert">
                          <FdAlert
                            variant="error"
                            message="Please select challenge(s) for this assessment"
                          />
                        </Box>
                      )}
                    </Box>
                    {activeTask && openDrawer && (
                      <ViewTaskDrawer
                        activeTaskData={activeTask}
                        openDrawer={openDrawer}
                        openDrawerCallBack={setOpenDrawer}
                        mainPageIds={['topnav', 'assessor']}
                      />
                    )}
                  </>
                ),
                2: (
                  <Box data-cy="messages">
                    <PreMessage />
                    <PostMessage />
                  </Box>
                ),
              }[activeStep]
            }
            <BasePageButtonContainer>
              <FdButton
                size="large"
                onClick={handleNext}
                disabled={createLoading || createModulePartLoading}
              >
                {createLoading || createModulePartLoading
                  ? 'Loading...'
                  : activeStep === steps.length - 1
                    ? 'Create Assessment'
                    : 'Next'}
              </FdButton>
              {activeStep !== 0 && (
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={handleBack}
                  data-cy="back-button"
                >
                  Back
                </FdButton>
              )}
              <FdButton
                size="large"
                variant="tertiary"
                onClick={() => singleSpa.navigateToUrl('/landing')}
                data-cy="cancel-button"
              >
                Cancel
              </FdButton>
            </BasePageButtonContainer>
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (isDuplicateMode) {
              if (
                window.location.pathname !==
                `/assessor/duplicate/${_assessmentId}`
              ) {
                history.goBack();
              }
            } else if (window.location.pathname !== '/assessor/create') {
              history.goBack();
            }
          }}
          afterConfirm={() =>
            isDuplicateMode
              ? warningToastMessage('Duplicate assessment not created')
              : warningToastMessage('Changes to assessment are not saved')
          }
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title={
                isDuplicateMode
                  ? 'Abandon Duplicate Creation?'
                  : 'Are you sure you want to leave?'
              }
              description={
                isDuplicateMode
                  ? 'Are you sure that you want to abandon creating this duplicate assessment? Any inputs made during the process will be lost. '
                  : 'You have unsaved changes. Click the Stay button to go back to the form and save your changes.'
              }
              confirm={isDuplicateMode ? 'Confirm' : 'Stay'}
              dismiss={isDuplicateMode ? 'Cancel' : 'Leave'}
              open
              onConfirm={isDuplicateMode ? onConfirm : onCancel}
              onDismiss={isDuplicateMode ? onCancel : onConfirm}
              data-cy="leave-modal"
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};
CreateAssessment.propTypes = {
  formMode: PropTypes.oneOf(['create', 'duplicate']).isRequired,
};
export default CreateAssessment;
