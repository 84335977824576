import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  BasePage,
  FdProgress,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import { listDashboardsByOrgId } from '../graphql/queries';
import Dashboard from '../components/Dashboard/Dashboard';

const ListDashboards = () => {
  const { user, orgId, loading, orgPricingTier, permissions } =
    useSnapshot(globalStore);
  const history = useHistory();

  const { data: dashboardsData, loading: dashboardsLoading } = useQuery(
    gql(listDashboardsByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );

  if (loading || dashboardsLoading) return <FdProgress />;

  const dashboards = dashboardsData?.listDashboardsByOrgId?.items;

  const canViewDashboards =
    orgPricingTier !== 'STARTER' &&
    user &&
    (Authorization.canManageEvents(permissions) ||
      Authorization.canViewInsights(permissions));

  if (dashboards?.length === 0 || !canViewDashboards) return history.goBack();

  return (
    <BasePage
      heading="Dashboards"
      breadCrumbs={[{ name: 'Home', url: '/assessor/dashboards' }]}
      currentPageBreadcrumbLabel="Dashboards"
      linkComponent={RouterLink}
    >
      <Grid container spacing={3} style={{ paddingTop: '16px' }}>
        {dashboards?.map((dashboard) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={dashboard.id}>
            <Dashboard dashboard={dashboard} />
          </Grid>
        ))}
      </Grid>
    </BasePage>
  );
};

export default ListDashboards;
