import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import {
  useAuthSession,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { listOnboardingsByUserId } from '../graphql/queries';
import Survey from '../pages/Survey';

const SurveyRoute = ({ ...rest }) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);
  const assessmentId = rest?.computedMatch.params?.assessmentId;
  const sharedLinkRoute =
    assessmentId && rest?.path === '/assessor/survey/assess/:assessmentId';

  const { data: onboardingData, loading: onboardingDataLoading } = useQuery(
    gql(listOnboardingsByUserId),
    {
      variables: {
        userId: user?.username,
      },
      skip: !user,
    },
  );

  if (globalSnap.loading || onboardingDataLoading) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;

        if (user && type === 'ADMIN') {
          return <Redirect to="/landing" />;
        }
        if (user && type === 'PARTICIPANT') {
          if (sharedLinkRoute) {
            return <Survey {...rest} {...props} />;
          }
          const isOnboardingComplete =
            !!onboardingData?.listOnboardingsByUserId?.items.length;
          // if survey already done, then redirect to homepage
          if (isOnboardingComplete) {
            return <Redirect to="/landing/landing-homepage" />;
          }
          return <Survey {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

export default SurveyRoute;
