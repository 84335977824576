import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const HeatMapCell = ({ label, color }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      style={{ background: color }}
      p={1}
    >
      {label}
    </Box>
  );
};

HeatMapCell.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default HeatMapCell;
