import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useQuery, gql, useMutation } from '@apollo/client';
import Countdown from 'react-countdown';
import { differenceInMinutes } from 'date-fns';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTable,
  FdChip,
  FdButton,
  FdModal,
  FdSelect,
  FdProgress,
  FdTypography,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import Summary from './Summary';
import {
  getSystemTime,
  listGroupsByAssessmenId,
  listGroupsByOrgId,
  listTaskAttemptsByAssessmentId,
} from '../../graphql/queries';
import { addUsers, removeGroupsFromAssessment } from '../../graphql/mutations';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';

const TeamsTab = ({
  orgId,
  assessmentId,
  status,
  statusColor,
  milliSecondsToFinish,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [showModal, setShowModal] = useState(false);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [deleteTeam, setDeleteTeam] = useState(undefined);
  const { data: groupsData, loading: groupsDataLoading } = useQueryRecursive(
    gql(listGroupsByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );
  const {
    data: groupsAssessmentData,
    loading: groupsAssessmentDataLoading,
    refetch: refetchGroupsByAssessmentId,
  } = useQueryRecursive(gql(listGroupsByAssessmenId), {
    variables: {
      assessmentId,
    },
  });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
  );

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listTaskAttemptsByAssessmentId), {
      variables: {
        assessmentId,
        limit: 1000,
      },
    });
  const [addUsersMutation, { loading: addUsersLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        successToastMessage('Team(s) added successfully');
        refetchGroupsByAssessmentId();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );
  const [removeGroupsMutation, { loading: removeGroupsLoading }] = useMutation(
    gql(removeGroupsFromAssessment),
    {
      onCompleted: (_data) => {
        successToastMessage('Team removed successfully');
        refetchGroupsByAssessmentId();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  if (
    groupsAssessmentDataLoading ||
    groupsDataLoading ||
    serverTimeLoading ||
    addUsersLoading ||
    listTaskAttemptsLoading ||
    removeGroupsLoading
  ) {
    return <FdProgress />;
  }

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        return rowData.status !== 'FINISHED' ? (
          <FdButton
            variant="tertiary"
            size="small"
            disabled={!hasManagePermission}
            onClick={() => setDeleteTeam(rowData.id)}
          >
            Remove
          </FdButton>
        ) : (
          <FdButton
            variant="tertiary"
            size="small"
            onClick={() =>
              window.open(
                `/user-management/groups/edit/${rowData.id}`,
                '_blank',
              )
            }
          >
            View
          </FdButton>
        );
      },
    },
  ];
  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    { field: 'participantCount', flex: 1, headerName: 'No of Participants' },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => (
        <FdChip
          color={getParticipantStatusColor(params?.row?.status || '')}
          size="small"
          label={getParticipantStatus(params?.row?.status || '')}
        />
      ),
    },
    { field: 'started', flex: 1, headerName: 'Started' },
    { field: 'duration', flex: 1, headerName: 'Duration' },
    { field: 'attempted', flex: 1, headerName: 'Attempted' },
    { field: 'solved', flex: 1, headerName: 'Solved' },
    {
      field: 'successRate',
      flex: 1,
      type: 'number',
      headerName: 'Success Rate',
      valueFormatter: (params) => `${params.value}%`,
    },
  ];
  const teamsToDisplay = groupsData?.listGroupsByOrgId?.items || [];
  const taskAttempts =
    listTaskAttemptsData?.listTaskAttemptsByAssessmentId?.items || [];

  const getTeamUsers = (groupId) => {
    const teamUsers =
      teamsToDisplay.find((td) => td.id === groupId)?.users.items || [];
    return teamUsers?.map((u) => u?.userId);
  };
  const getUserTaskAttempts = (groupId) => {
    const teamUserIds = getTeamUsers(groupId);
    return taskAttempts.filter((ta) => teamUserIds.includes(ta.userId)) || [];
  };
  const getTeamSolved = (groupId) => {
    const userAttempts = getUserTaskAttempts(groupId);
    return userAttempts?.filter((ua) => ua.success).length || 0;
  };
  const teamsTableRows =
    groupsAssessmentData?.listGroupsByAssessmenId?.items
      .filter((ga) => ga.status !== 'REMOVED' && ga.group)
      .map((ga) => {
        const attempted = getUserTaskAttempts(ga.groupId)?.length || 0;
        const solved = getTeamSolved(ga.groupId) || 0;
        return {
          id: ga.group?.id,
          name: ga.group?.name,
          participantCount: teamsToDisplay.find((td) => td.id === ga.group?.id)
            ?.users?.items.length,
          status:
            ga.status === 'STARTED' && status === 'Ended'
              ? 'NOT COMPLETED'
              : ga.status,
          started: ga.startedOn ? getDateTimeZoneFormatted(ga.startedOn) : '-',
          duration:
            ga.finishedOn && ga.startedOn
              ? differenceInMinutes(
                  new Date(ga.finishedOn),
                  new Date(ga.startedOn),
                )
              : '-',
          attempted,
          solved,
          successRate:
            attempted > 0 ? Math.round((solved / attempted) * 100) : 0,
        };
      }) || [];
  const invitedTeamsCount = teamsTableRows.length;
  const assessedTeamsCount = teamsTableRows.filter(
    (r) => r.status === 'Completed',
  ).length;

  return (
    <Box>
      <Summary
        data={[
          {
            value: (
              <Box mb={1}>
                <FdChip color={statusColor} size="small" label={status} />
              </Box>
            ),
            description: 'Assessment Status',
          },
          {
            value: (
              <Box mb={1}>
                <Countdown
                  date={
                    new Date(serverTime?.getSystemTime).getTime() +
                    milliSecondsToFinish
                  }
                  daysInHours
                />
              </Box>
            ),
            description: 'Time Remaining',
          },
          {
            value: <Box mb={1}>{invitedTeamsCount}</Box>,
            description: 'Teams Invited',
          },
          {
            value: <Box mb={1}>{assessedTeamsCount}</Box>,
            description: 'Teams Assessed',
          },
          {
            value: (
              <Box mb={1}>
                {assessedTeamsCount
                  ? `${Math.ceil(
                      (assessedTeamsCount / invitedTeamsCount) * 100,
                    )}%`
                  : '-'}
              </Box>
            ),
            description: 'Completion Rate',
          },
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box
        mt={2}
        mb={2}
        height="725px"
        width="100%"
        style={{ backgroundColor: 'white' }}
        data-cy="teams-table"
      >
        <FdTable
          toolbarSettings={{
            title: 'Teams',
            headerActions:
              status !== 'Ended' && hasManagePermission
                ? [
                    {
                      label: 'Add Teams',
                      onClick: () => {
                        setShowModal(true);
                        setTeamsSelected([]);
                      },
                    },
                  ]
                : [],
            searchBox: true,
          }}
          actions={actions}
          rows={teamsTableRows}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          gridId="assessor-admin-assessment-teams"
        />
      </Box>
      <FdModal
        size="md"
        title="Add Teams"
        description={
          <Box>
            <FdSelect
              id="selectedTeams"
              label="Selected groups shall be added to team-based assessment."
              options={teamsToDisplay.map((t) => t.name)}
              onChange={(values) => setTeamsSelected(values)}
              multiple
              fullWidth
            />
          </Box>
        }
        dismiss="CANCEL"
        confirm={addUsersLoading ? 'Loading...' : 'Add'}
        open={showModal}
        onDismiss={() => setShowModal(false)}
        onConfirm={() => {
          addUsersMutation({
            variables: {
              orgId,
              type: 'PARTICIPANT',
              participantEventType: 'ASSESSMENT',
              assessmentId,
              groups: teamsToDisplay
                .filter((ts) => teamsSelected.includes(ts.name))
                .map((tsi) => tsi.id),
            },
          });
          setShowModal(false);
        }}
        data-cy="add-teams-modal"
      />
      <FdModal
        size="xs"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Remove Team?</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to remove the team?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                All information about the teams&apos;s performance in the
                assessment will be lost.
              </FdTypography>
            </Box>
          </Box>
        }
        dismiss="CANCEL"
        confirm="OK"
        open={deleteTeam}
        onDismiss={() => setDeleteTeam(undefined)}
        onConfirm={() => {
          removeGroupsMutation({
            variables: {
              groupIds: [deleteTeam],
              assessmentId,
            },
          });
          setDeleteTeam(undefined);
        }}
        data-cy="delete-team-modal"
      />
    </Box>
  );
};

TeamsTab.propTypes = {
  orgId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusColor: PropTypes.string.isRequired,
  milliSecondsToFinish: PropTypes.number.isRequired,
};

export default TeamsTab;
