import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider } from '@mui/material';
import {
  FdTypography,
  FdChip,
  FdSkeleton,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import { getParticipantAssessmentStatusColor } from '../../shared/utils/getStatusColor';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';

const TaskDrawerHeaderParticipant = ({
  activeTask,
  taskStatus,
  taskAttempts,
  loading,
}) => {
  return (
    <>
      <Box mb={2} className="flex gap-2">
        <FdHighlightChip
          text={getDifficultyLabel(activeTask?.difficulty)}
          condensed
        />
        <FdHighlightChip
          text={`${activeTask?.recommendedPoints} pts`}
          condensed
        />
      </Box>
      <Card variant="outlined" className="py-3">
        <Box className="flex w-full justify-around">
          <Box className="flex flex-col items-center">
            <FdHighlightChip text={activeTask?.specialty} />
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Specialty
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdSkeleton loading={loading} height={24} width={89}>
              <FdChip
                color={getParticipantAssessmentStatusColor(taskStatus)}
                size="medium"
                label={taskStatus}
                style={{ height: '24px' }}
              />
            </FdSkeleton>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Status
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdSkeleton loading={loading} height={24} width={49}>
              <FdTypography variant="subtitle1">{taskAttempts}</FdTypography>
            </FdSkeleton>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Attempts
            </FdTypography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

TaskDrawerHeaderParticipant.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    recommendedPoints: PropTypes.number,
    difficulty: PropTypes.string,
  }).isRequired,
  taskStatus: PropTypes.string.isRequired,
  taskAttempts: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TaskDrawerHeaderParticipant;
