import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdMarkdownEditor } from '@fifthdomain/fe-shared';

const PostMessage = ({ heading }) => {
  const { control } = useFormContext();
  return (
    <FdCard
      variant="outlined"
      heading={heading}
      subHeading="The following post-assessment message will be displayed to participants after they have completed the assessment."
    >
      <Box mt={0}>
        <Controller
          control={control}
          name="postMessage"
          render={({ field, fieldState: { error } }) => (
            <Box mb={2} data-cy="post-message">
              <FdMarkdownEditor
                id="postMessage"
                name="postMessage"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="Message"
                required
                error={error}
                errorText={error && error.message}
                {...field}
              />
            </Box>
          )}
        />
      </Box>
    </FdCard>
  );
};

PostMessage.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

PostMessage.defaultProps = {
  heading: 'Post-Assessment Message',
};

export default PostMessage;
