import React from 'react';
import { Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import {
  BasePage,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import AssessmentRules from './AssessmentRules';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import { listTaskAttemptAggregatesByUserId } from '../queries/customQueries';
import { getUserAssessment } from '../graphql/queries';
import useStopAssessment from '../hooks/useStopAssessment';
import { getAssessmentFinishDateTime } from '../shared/utils/dateUtils';

const AssessmentOverview = () => {
  const { userName, userId } = useSnapshot(globalStore);
  const { assessmentId } = useParams();
  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
      onCompleted: (_data) => {
        // redirect to home page if not started
        if (_data?.getUserAssessment?.status !== 'STARTED') {
          singleSpa.navigateToUrl('/landing/landing-homepage');
        }
      },
    },
  );
  const assessmentDataFiltered = assessmentData?.getUserAssessment || [];
  console.log('assessmentDataFiltered', assessmentData);

  const {
    data: tasksAttemptsAssessmentData,
    loading: tasksAttemptsAssessmentDataLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
      userId,
      limit: 1000,
    },
    skip: !assessmentDataFiltered?.assessment?.id,
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(false, 'ASSESSMENT');

  if (
    assessmentLoading ||
    tasksAttemptsAssessmentDataLoading ||
    stopAssessmentInProgress
  ) {
    return <FdLoadingSpinner />;
  }

  const tasksAttemptsData =
    tasksAttemptsAssessmentData?.listTaskAttemptAggregatesByUserId?.items || [];

  const {
    assessment: { name, hours, minutes },
    id: userAssessmentId,
    startedOn,
  } = assessmentDataFiltered;

  const tasksCompleted =
    tasksAttemptsData?.filter((t) => t?.status === 'COMPLETED').length || 0;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name,
            path: `/assessor/assessment-tasks/${assessmentId}`,
            type: 'ASSESSMENT',
          },
        ]}
        page={{ name: 'Overview' }}
      />
      <BasePage heading={name} data-cy="overview-page">
        <Box mb={2}>
          <AssessmentSummary
            assessment={{
              hours,
              minutes,
              endDateTime: getAssessmentFinishDateTime(
                startedOn,
                hours,
                minutes,
              ),
              tasksCompleted,
            }}
            onFinish={() =>
              stopAssessment({
                variables: {
                  userAssessmentId,
                },
              })
            }
          />
        </Box>
        <AssessmentRules
          videoUrl={assessmentDataFiltered?.assessment?.videoUrl}
          userFullName={userName}
          preMessage={assessmentDataFiltered?.assessment?.preMessage}
        />
      </BasePage>
    </Box>
  );
};

export default AssessmentOverview;
