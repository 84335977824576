import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography, FdAlert } from '@fifthdomain/fe-shared';
import FormIcon from '../../shared/images/formIcon.svg';
import RankingTable from './RankingTable';
import { LineNivo } from '../Charts';
import ComparisonChartLegend from './ComparisonChartLegend';
import { filterBySkill, getRankingNormalizedRows } from './overviewUtils';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import { lineChartColors } from '../../constants';

const ComparisonRanking = ({ data, onParticipantClick }) => {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const {
    rankingTableRows,
    rankingNormalizedRows,
    chartCohortRow,
    allSkills,
    tasks,
    participantsFinished,
    completedTasks,
    allTaskAttempts,
    maxDurationInMins,
    timeSpentData,
    loading,
    teamBased,
    totalPointsScoredInAssessment,
    participantsFinishedAssessment,
    teamGroups,
    tasksOpened,
  } = data;
  const chartData = rankingNormalizedRows
    ?.filter((c) => selectedCandidates.includes(c.id))
    .map((c) => {
      return {
        id: c.name,
        userId: c.id,
        data: allSkills?.map((s) => {
          const skillTasks = filterBySkill(tasks?.items, s.skillName);
          const skillTotalAssessmentPoints = skillTasks?.reduce(
            (acc, i) => acc + i.task?.recommendedPoints,
            0,
          );

          const normalizedRows = getRankingNormalizedRows({
            _participantsFinished: participantsFinished,
            _completedTasks: filterBySkill(completedTasks, s.skillName),
            _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
            _totalAssessmentPoints: skillTotalAssessmentPoints,
            _maxDurationInMins: maxDurationInMins,
            _timeSpentData: timeSpentData,
            _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
            _teamBased: teamBased,
            _teams: participantsFinishedAssessment,
            _teamGroups: teamGroups,
            _tasksOpened: filterBySkill(tasksOpened, s.skillName),
          });

          return {
            x: s.skillAlias,
            y: normalizedRows?.find((nr) => nr?.id === c.id)?.overallScore || 0,
          };
        }),
        rank: c.rank,
      };
    });

  const chartDataRows = [chartCohortRow, ...chartData];
  const teamOrCandidateText = teamBased ? 'teams' : 'participants';
  const getSkillName = (alias) =>
    allSkills?.find((s) => s.skillAlias === alias)?.skillName || '';
  const getColor = (c) =>
    lineChartColors[selectedCandidates.indexOf(c.userId) + 1];

  return (
    <Box mt={3}>
      <FdTypography variant="body2">
        Here you can see overall top performers. You can sort each column to see
        who was the best in that particular metric. Success Efficiency and Speed
        are represented by distribution bars. Hover over the distribution bar
        info icon to understand how to read it.
      </FdTypography>
      <TableHeaderColumnWithTooltip
        title="Distribution bars"
        tooltipText={
          <Box>
            In the distribution bars, the shaded part shows one standard
            deviation of variation in the scores of the cohort from the average.
            <Box mt={1}>
              Simply put, a wider shaded region indicates that the scores of the
              cohort are more spread out from the average. Conversely, a smaller
              shaded region suggests that the majority of scores are clustered
              closely around the average.
            </Box>
            <Box mt={1}>
              {`The dark line on the bar shows where the ${
                teamBased ? 'team' : 'participant'
              }'s score is for that measure.`}
            </Box>
          </Box>
        }
        mb={2}
      />
      <Box mt={2}>
        <FdAlert
          customIcon={<img src={FormIcon} alt="icon" />}
          variant="info"
          message={`To compare ${
            teamBased ? 'teams' : 'participants'
          } amongst each other, simply select the checkboxes next to their names and refer to the skill line chart below the leaderboard. You can select upto 5 ${
            teamBased ? 'teams' : 'participants'
          } to compare.`}
        />
      </Box>
      <RankingTable
        rows={rankingTableRows}
        selectedCandidates={selectedCandidates}
        setSelectedCandidates={setSelectedCandidates}
        loading={loading}
        onParticipantClick={onParticipantClick}
        teamBased={teamBased}
      />
      <Box mt={2}>
        <Box>
          <FdTypography variant="subtitle1">
            {`Comparison between ${teamOrCandidateText}`}
          </FdTypography>
        </Box>
        <Box my={1}>
          <FdTypography variant="body2" color="secondary">
            {`The coloured lines represent the ${teamOrCandidateText} selected to be compared. Hover over the vertices to see their performance score. The performance score is a function of the success in that skill as well as the speed and efficiency.`}
          </FdTypography>
        </Box>
        <Box display="flex" height="100%" width="100%">
          <Box height="200px" width="85%">
            <LineNivo
              data={chartDataRows}
              colors={getColor}
              showDashedForId="Cohort Average"
              tooltip={(d) => {
                const { serieId, data: _data } = d.point;
                const skillName = getSkillName(_data?.x);
                return (
                  <Box
                    p={1}
                    style={{
                      backgroundColor: '#6E6E6E',
                      borderRadius: '4px',
                    }}
                  >
                    <FdTypography variant="captiontext1" color="contrastText">
                      {serieId}
                    </FdTypography>
                    <Box mt={0.5}>
                      <FdTypography variant="captiontext2" color="contrastText">
                        {`${skillName}(${_data?.x}): ${Math.round(
                          _data?.yFormatted,
                        )}`}
                      </FdTypography>
                    </Box>
                  </Box>
                );
              }}
            />
          </Box>
          <ComparisonChartLegend
            chartData={chartData}
            teamBased={teamBased}
            selectedCandidates={selectedCandidates}
          />
        </Box>
      </Box>
    </Box>
  );
};

ComparisonRanking.propTypes = {
  data: PropTypes.shape({
    rankingTableRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rankingNormalizedRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    chartCohortRow: PropTypes.shape({}).isRequired,
    allSkills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    participantsFinished: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    maxDurationInMins: PropTypes.number.isRequired,
    timeSpentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loading: PropTypes.bool.isRequired,
    teamBased: PropTypes.bool.isRequired,
    totalPointsScoredInAssessment: PropTypes.number.isRequired,
    participantsFinishedAssessment: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onParticipantClick: PropTypes.func.isRequired,
};

export default ComparisonRanking;
