import React, { useEffect, Fragment } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { isValid as isValidDate } from 'date-fns';
import { useWatch, Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdDateTimePicker,
  FdTypography as Typography,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';

const Availability = ({
  heading,
  showEdit,
  editAvailability,
  isDuplicateMode,
}) => {
  const { control, trigger } = useFormContext();
  const watchStartDateTime = useWatch({
    control,
    name: 'startDateTime',
  });
  const watchEndDateTime = useWatch({
    control,
    name: 'endDateTime',
  });

  // force trigger to validate, as react-hook-form sends old error state
  useEffect(() => {
    if (watchStartDateTime) {
      trigger(['startDateTime', 'endDateTime']);
    }
  }, [watchStartDateTime, trigger]);

  useEffect(() => {
    if (watchEndDateTime) {
      trigger(['startDateTime', 'endDateTime']);
    }
  }, [watchEndDateTime, trigger]);

  return (
    <FdCard
      variant="outlined"
      heading={heading || 'Availability'}
      variant="outlined"
      subHeading="Select when the assessment will start and expire. Participants can start the assessment at any stage in between the times listed here."
      data-cy="card-availability"
    >
      {isDuplicateMode && (
        <Box my={2}>
          <FdAlert
            variant="info"
            message={
              <Typography variant="body2">
                Start and End Dates and Times have not been carried forward from
                the original competition being duplicated. Please select new
                Dates and Times as required.
              </Typography>
            }
          />
        </Box>
      )}
      <Box>
        <Controller
          control={control}
          name="startDateTime"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <>
              {!showEdit && editAvailability ? (
                <>
                  <Typography variant="subtitle1" data-cy="subtitle">
                    Assessment Start
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    data-cy="start-date-time-text"
                  >
                    {getDateTimeZoneFormatted(rest.value)}
                  </Typography>
                </>
              ) : (
                <FdDateTimePicker
                  label="Assessment Start (optional)"
                  value={rest.value}
                  disablePast
                  minDateMessage="Start Date cannot be in the past"
                  helperText={
                    rest.value && !isValidDate(new Date(rest.value))
                      ? 'Start Date and Time must be entered in the format “DD/MM/YYYY HH:MM”'
                      : (error && error.message) ||
                        'Participants will not be able to access the assessment until this date'
                  }
                  error={error}
                  data-cy="start-date-time"
                  {...rest}
                  inputRef={ref}
                />
              )}
            </>
          )}
        />
      </Box>
      <Box mt={6} mb={3}>
        <Controller
          control={control}
          name="endDateTime"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <FdDateTimePicker
              label="Assessment End"
              value={rest.value}
              disablePast
              minDateMessage="Start Date cannot be in the past"
              helperText={
                rest.value && !isValidDate(new Date(rest.value))
                  ? 'End Date and Time must be entered in the format “DD/MM/YYYY HH:MM”'
                  : (error && error.message) ||
                    'Participants will not be able to access the assessment after this date'
              }
              error={error}
              data-cy="end-date-time"
              {...rest}
              inputRef={ref}
            />
          )}
        />
      </Box>
    </FdCard>
  );
};

Availability.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showEdit: PropTypes.bool,
  editAvailability: PropTypes.bool,
  isDuplicateMode: PropTypes.bool.isRequired,
};

Availability.defaultProps = {
  heading: 'Availability',
  showEdit: false,
  editAvailability: undefined,
};

export default Availability;
