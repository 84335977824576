import React from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdTextField,
  FdDateTimePicker,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { isValid as isValidDate } from 'date-fns';

const AssessmentTemplateDetails = () => {
  const { control } = useFormContext();
  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <FdTypography variant="body2" color="secondary">
        Give this assessment a name.Your assessment will appear under this name
        in your library. This will be displayed to participants.
      </FdTypography>
      <Controller
        control={control}
        name="name"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="name"
              label="Assessment Name"
              required
              fullWidth
              error={error}
              helperText={error && error.message}
              data-cy="name"
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />
      <FdTypography variant="body2" color="secondary">
        Select when the assessment will start and expire. Participants can start
        the assessment at any stage in between the times listed here.
      </FdTypography>

      <Controller
        control={control}
        name="startDateTime"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1}>
            <FdDateTimePicker
              label="Assessment Start (optional)"
              value={rest.value}
              disablePast
              minDateMessage="Start Date cannot be in the past"
              helperText={
                rest.value && !isValidDate(rest.value)
                  ? 'Start Date and Time must be entered in the format “DD/MM/YYY HH:MM”'
                  : (error && error.message) ||
                    'Participants will not be able to access the assessment until this date'
              }
              error={error}
              data-cy="start-date-time"
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />

      <Box mt={6} mb={3}>
        <Controller
          control={control}
          name="endDateTime"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <FdDateTimePicker
              label="Assessment End"
              value={rest.value}
              disablePast
              minDateMessage="Start Date cannot be in the past"
              helperText={
                rest.value && !isValidDate(rest.value)
                  ? 'End Date and Time must be entered in the format “DD/MM/YYY HH:MM”'
                  : (error && error.message) ||
                    'Participants will not be able to access the assessment after this date'
              }
              error={error}
              data-cy="end-date-time"
              {...rest}
              inputRef={ref}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default AssessmentTemplateDetails;
