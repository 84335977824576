import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  FdModal,
  FdMultiChipTextField,
  InformationBox,
  FdAlert,
  FdTab,
  FdAutocomplete,
  FdTypography,
  FdDelayed,
  FdLoadingSpinner,
  FdTextField,
  useSnapshot,
  globalStore,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';

// regex test for email validation based on RFC 5322
const validEmail = (email) =>
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

const useStyles = makeStyles()((theme) => ({
  note: {
    color: `${theme.palette.primary.dark} !important`,
  },
}));

const InviteUsers = ({
  users,
  usersCallBack,
  loading,
  orgUsersLoading,
  title,
  maxInviteesError,
  listOrgUsers,
  listAllFinishedAssessments,
  assessmentId,
}) => {
  const [emails, setEmails] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [eventsSelected, setEventsSelected] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [error, setError] = useState(false);
  const globalSnap = useSnapshot(globalStore);
  const { classes } = useStyles();
  const eventUrl = `${window.location.protocol}//${window.location.host}/a/login/e/assess/${assessmentId}/org/${globalSnap.orgId}`;

  const onDeleteEmail = (_email) => {
    setUsersSelected((_usersSelected) =>
      _usersSelected.filter((us) => us.email !== _email),
    );
    setRefreshList(!refreshList);
  };

  return (
    <FdModal
      size="md"
      title={title}
      description="Invite participants to this event via the User Directory and/or Event Participant Lists. Selected users will appear in the Invitee Email Addresses box."
      confirm={loading ? 'Loading...' : 'INVITE'}
      disableConfirm={loading}
      dismiss="CANCEL"
      open={users}
      onConfirm={() => {
        if (emails.length < 1) {
          setError('Enter at least 1 email');
          return;
        }
        if (emails.some((email) => !validEmail(email))) {
          setError('One or more of the email addresses are invalid');
          return;
        }
        usersCallBack(emails);
      }}
      onDismiss={() => {
        usersCallBack(false, emails.length !== 0);
      }}
      data-cy="invite-users-modal"
    >
      <Box>
        <Box my={2} className={classes.note}>
          <FdAlert
            alertTitle="Information to Note"
            variant="info"
            message={
              <Box>
                Participants who have already been invited to this event will
                not receive duplicate invitations.
                <br />
                <br />
                Inviting over 50 participants in one go may cause the invitation
                instance to fail.
              </Box>
            }
          />
        </Box>
        <Box display="flex" alignItems="center">
          <FdTextField
            id="copy-url"
            label="Copy Event URL"
            required
            value={eventUrl}
            width="100%"
            helperText="Press the copy icon to copy the Event URL for distribution to Participants. "
          />
          <Button
            onClick={() => {
              navigator.clipboard.writeText(eventUrl).then(() => {
                successToastMessage('Event URL copied to clipboard');
              });
            }}
            variant="outlined"
            style={{ height: '48px' }}
          >
            <FileCopyIcon />
          </Button>
        </Box>
        <Box display="flex" alignItems="flex-end" mb={2} mt={2}>
          <FdMultiChipTextField
            id="Emails"
            width="100%"
            label="Invitee Email Addresses"
            placeholder="Emails"
            helperText="Users selected from the search functions below will appear in the box above. You may also paste or type email addresses directly into the box. "
            required
            values={emails}
            setValues={setEmails}
            onDelete={onDeleteEmail}
            data-cy="emails-selector"
          />
        </Box>
        {error && (
          <Box display="flex">
            <InformationBox variant="error" status="" message={error} />
          </Box>
        )}
        {maxInviteesError && (
          <Box mt={6}>
            <FdAlert
              alertTitle="Invitations limit exceeded!"
              variant="warning"
              message={maxInviteesError}
            />
          </Box>
        )}
        <Box mt={1}>
          <FdTab
            label={[
              {
                label: 'Add Via User Directory',
                index: 0,
                data: (
                  <Box>
                    {orgUsersLoading ? (
                      <FdLoadingSpinner />
                    ) : (
                      <FdDelayed delay={0} triggerField={refreshList}>
                        <FdAutocomplete
                          label="Search in User Directory"
                          helperText="Type names and/or email addresses of existing users to add them to the invitation. Click the filter icon to filter your search by existing tags."
                          options={listOrgUsers?.map((u) => ({
                            id: u.id,
                            name: u.name,
                            email: u.email,
                            type: u.type,
                          }))}
                          multiple
                          fullWidth
                          selectAllLabel="Select all"
                          optionLabel="name"
                          optionValue="id"
                          defaultSelected={usersSelected}
                          ListItemTextRenderer={({ option }) => {
                            return (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box display="flex" flexDirection="column">
                                  <FdTypography variant="body1">
                                    {option?.name}
                                  </FdTypography>
                                  <FdTypography variant="caption">
                                    {option?.email}
                                  </FdTypography>
                                </Box>
                                <Box mr={1}>
                                  <FdTypography variant="caption">
                                    {upperCaseFirstLetter(option?.type)}
                                  </FdTypography>
                                </Box>
                              </Box>
                            );
                          }}
                          onChange={(currentSelection) => {
                            const emailsSelected = currentSelection?.map(
                              (c) => c.email,
                            );
                            setEmails((_emails) => [
                              ...new Set([..._emails, ...emailsSelected]),
                            ]);
                            setUsersSelected(currentSelection);
                          }}
                        />
                      </FdDelayed>
                    )}
                  </Box>
                ),
              },
              {
                label: 'Add Via Event Participant Lists',
                index: 1,
                data: (
                  <Box>
                    <FdAutocomplete
                      label="Search Events for Participants"
                      helperText="Type event names and select from the dropdown to add all participants in the selected event(s) to this invitation instance."
                      options={listAllFinishedAssessments
                        ?.map((u) => ({
                          id: u.id,
                          name: u.name,
                          type: u.participantEventType,
                        }))
                        .filter((t) => t.type !== null)
                        .sort((a, b) => {
                          const nameComparison = a.type?.localeCompare(b?.type);
                          if (nameComparison !== 0) {
                            return nameComparison;
                          }
                          return a.name?.localeCompare(b?.name);
                        })}
                      multiple
                      fullWidth
                      selectAllLabel="Select all"
                      optionLabel="name"
                      optionValue="id"
                      defaultSelected={eventsSelected}
                      ListItemTextRenderer={({ option }) => {
                        let EventIcon;
                        switch (option?.type) {
                          case 'COMPETITION':
                            EventIcon = <EmojiEventsIcon />;
                            break;
                          case 'COURSE':
                            EventIcon = (
                              <WbIncandescentIcon
                                style={{ transform: 'rotateX(180deg)' }}
                              />
                            );
                            break;
                          default:
                            EventIcon = <AssignmentIcon />;
                            break;
                        }
                        return (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Box display="flex" alignItems="center">
                              <Box mr={1}>
                                <FdTypography variant="body1">
                                  {EventIcon}
                                </FdTypography>
                              </Box>
                              <Box>
                                <FdTypography variant="body1">
                                  {option?.name}
                                </FdTypography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }}
                      onChange={(currentSelection) => {
                        const eventIds = currentSelection?.map((c) => c.id);
                        const selectedAssessmentsUserEmails =
                          listAllFinishedAssessments
                            .filter((a) => eventIds.includes(a.id))
                            ?.map((u) => u.users?.items)
                            .flat()
                            .map((e) => e.user?.email) || [];
                        setEmails((_emails) => [
                          ...new Set([
                            ..._emails,
                            ...selectedAssessmentsUserEmails,
                          ]),
                        ]);
                        setEventsSelected(currentSelection);
                      }}
                    />
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </FdModal>
  );
};

InviteUsers.propTypes = {
  users: PropTypes.bool,
  usersCallBack: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  orgUsersLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  maxInviteesError: PropTypes.string,
  listOrgUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listAllFinishedAssessments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assessmentId: PropTypes.string.isRequired,
};

InviteUsers.defaultProps = {
  users: false,
  usersCallBack: () => {},
  maxInviteesError: '',
};
export default InviteUsers;
