import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';

const TableHeaderColumnWithTooltip = ({ title, tooltipText, ...props }) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <FdTypography variant="subtitle2">{title}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

TableHeaderColumnWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
};

export default TableHeaderColumnWithTooltip;
