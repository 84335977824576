/* eslint-disable prefer-destructuring */
import { colors } from '@mui/material';

export const getCellColor = (_value) => {
  const { green } = colors;
  let color;

  switch (true) {
    case _value >= 1 && _value <= 33:
      color = green[50];
      break;
    case _value > 33 && _value <= 66:
      color = green[200];
      break;
    case _value > 66:
      color = green[400];
      break;
    default:
      color = '';
      break;
  }
  return color;
};

export const getAttemptStatusColor = (attempts, efficiency) => {
  const { grey, red } = colors;

  if (!attempts || attempts.length === 0) {
    return grey[400];
  }
  const hasSuccess = attempts.some((a) => a.status === 'COMPLETED');
  if (hasSuccess) {
    return getCellColor(efficiency);
  }
  return red[300];
};
