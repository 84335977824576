import React from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import {
  useAuthSession,
  globalStore,
  useSnapshot,
  Authorization,
} from '@fifthdomain/fe-shared';
import OptionalOnboardingRoute from './OptionalOnboardingRoute';
import UserAssessmentProvider from '../providers/UserAssessmentProvider';

const ParticipantRoute = ({ component: Component, ...rest }) => {
  const assessmentId = rest?.computedMatch.params?.assessmentId;
  const orgId = rest?.computedMatch.params?.orgId;
  const sharedLinkRoute =
    assessmentId && rest?.path === '/assessor/assess/:assessmentId/org/:orgId';
  const history = useHistory();
  const { user } = useAuthSession({
    onError: () => {
      // via custom url copy
      if (sharedLinkRoute) {
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/a/login/e/assess/${assessmentId}/org/${orgId}`,
        );
      } else {
        history.push('/');
      }
    },
  });
  const globalSnap = useSnapshot(globalStore);

  if (globalSnap.loading) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;

        if (user) {
          const allowedManagerPermissions =
            Authorization.canManageEvents(globalSnap?.permissions) ||
            Authorization.canViewInsights(globalSnap?.permissions);

          if (type === 'PARTICIPANT') {
            if (sharedLinkRoute) {
              return <UserAssessmentProvider assessmentId={assessmentId} />;
            }
            // redirect participant to survey if they haven't done.
            return (
              <OptionalOnboardingRoute
                userId={user?.username}
                component={Component}
                {...rest}
                {...props}
              />
            );
          }
          if (
            type === 'ADMIN' ||
            (type === 'MANAGER' && allowedManagerPermissions)
          ) {
            return <Redirect to="/landing" />;
          }
          if (type === 'MANAGER' && !allowedManagerPermissions) {
            singleSpa.navigateToUrl('/user-management');
            return null;
          }
        }
        return null;
      }}
    />
  );
};

ParticipantRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ParticipantRoute;
