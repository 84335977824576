import React, { createRef, useEffect } from 'react';
import * as singleSpa from 'single-spa';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  FdProgress,
  FdButton,
  BasePage,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import useOrgId from '../hooks/useOrgId';
import { getDashboard } from '../graphql/queries';
import { generateEmbedUrl } from '../graphql/mutations';

const useStyle = makeStyles()(() => ({
  root: {
    '& [class*="quicksight-embedding-iframe"]': {
      width: '98%',
      height: '84vh',
      margin: '0.5rem',
    },
  },
}));

const QuickSight = () => {
  const dashboardRef = createRef();
  const { user, userLoading } = useOrgId();
  const { classes } = useStyle();
  const { dashboardId } = useParams();

  const { data: dashboardData, loading: dashboardLoading } = useQuery(
    gql(getDashboard),
    {
      variables: {
        id: dashboardId,
      },
      skip: !dashboardId,
    },
  );

  const [generateEmbedUrlMutation, { loading: embedUrlLoading }] = useMutation(
    gql(generateEmbedUrl),
    {
      variables: {
        dashboardId,
        openIdToken: user?.signInUserSession?.idToken?.jwtToken,
      },
      onCompleted: (_data) => {
        const url = _data?.generateEmbedUrl?.url;
        const options = {
          url,
          container: dashboardRef.current,
          scrolling: 'no',
          iframeResizeOnSheetChange: false,
          locale: 'en-US',
          footerPaddingEnabled: true,
          sheetTabsDisabled: false,
          printEnabled: false,
          undoRedoDisabled: false,
          resetDisabled: false,
          className: 'quicksight-embedding-iframe',
        };

        embedDashboard(options);
      },
    },
  );

  useEffect(() => {
    generateEmbedUrlMutation();
  }, [generateEmbedUrlMutation]);

  if (userLoading || dashboardLoading || embedUrlLoading) return <FdProgress />;

  const dashboardInfo = dashboardData?.getDashboard || {};

  return (
    <BasePage
      heading={dashboardInfo?.name}
      breadCrumbs={[
        { name: 'Home', url: '/landing' },
        { name: 'Dashboards', url: '/assessor/dashboards' },
      ]}
      currentPageBreadcrumbLabel={dashboardInfo?.name}
      linkComponent={RouterLink}
    >
      <Box className={classes.root}>
        <Box pl={0.5}>
          <FdTypography variant="body1">
            {dashboardInfo?.description}
          </FdTypography>
        </Box>
        <Box ref={dashboardRef} />
        <Box>
          <FdButton
            variant="secondary"
            onClick={() => singleSpa.navigateToUrl('/assessor/dashboards')}
          >
            BACK
          </FdButton>
        </Box>
      </Box>
    </BasePage>
  );
};

export default QuickSight;
