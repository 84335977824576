import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import NavigationPrompt from 'react-router-navigation-prompt';
import * as singleSpa from 'single-spa';
import WarningIcon from '@mui/icons-material/Warning';
import {
  BasePage,
  BasePageButtonContainer,
  FdLoadingSpinner,
  FdTypography,
  FdTextField,
  FdButton,
  FdCard,
  FdModal,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import { successToastMessage } from '../shared/utils/toast';
import {
  listPostAssessmentReflectionAttempts,
  listPostAssessmentReflectionQuestions,
} from '../graphql/queries';
import { submitPostAssessmentReflection } from '../graphql/mutations';
import { getUserAssessment } from '../queries/customQueries';

const AssessmentPostReflection = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { assessmentId } = useParams();

  const validationSchema = Yup.object().shape({
    reflections: Yup.array().of(
      Yup.object().shape({
        qnNo: Yup.number(),
        id: Yup.string(),
        question: Yup.string(),
        answer: Yup.string(),
      }),
    ),
  });
  const {
    reset,
    control,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      reflections: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { data: userAssessment, loading: userAssessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    data: postAssessmentReflectionAttempts,
    loading: postAssessmentReflectionAttemptsLoading,
  } = useQueryRecursive(gql(listPostAssessmentReflectionAttempts), {
    variables: {
      filter: {
        userId: { eq: userAssessment?.getUserAssessment?.userId },
        assessmentId: {
          eq: userAssessment?.getUserAssessment?.userAssessmentAssessmentId,
        },
      },
    },
    skip: !userAssessment,
  });

  const [
    submitPostAssessmentReflectionMutation,
    { loading: submitPostAssessmentReflectionLoading },
  ] = useMutation(gql(submitPostAssessmentReflection), {
    onCompleted: () => {
      reset();
      successToastMessage('Success! Reflection Submitted');
      singleSpa.navigateToUrl(`/assessor/assessment-complete/${assessmentId}`);
    },
  });

  const reflectionQuestions = watch('reflections');

  const { loading: postAssessmentReflectionQuestionsLoading } =
    useQueryRecursive(gql(listPostAssessmentReflectionQuestions), {
      onCompleted: (_data) => {
        const sortedReflectionQuestions = [
          ..._data.listPostAssessmentReflectionQuestions.items,
        ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        // reset values for reflections
        reset({
          reflections: sortedReflectionQuestions.map((qn, idx) => ({
            qnNo: idx + 1,
            id: qn.id,
            question: qn.name,
            answer: '',
          })),
        });
      },
    });

  if (
    userAssessmentLoading ||
    postAssessmentReflectionAttemptsLoading ||
    postAssessmentReflectionQuestionsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const isSubmitted =
    postAssessmentReflectionAttempts?.listPostAssessmentReflectionAttempts
      ?.items?.length > 0;

  const hasPostAssessmentReflection =
    userAssessment?.getUserAssessment?.assessment?.showPostAssessmentReflection;

  if (!hasPostAssessmentReflection || isSubmitted) {
    singleSpa.navigateToUrl(`/assessor/assessment-complete/${assessmentId}`);
  }

  return (
    <BasePage heading="" data-cy="post-assessment-reflection">
      <FdCard
        heading="Post-Assessment Reflection"
        subHeading="You have been asked to submit a reflection on the assessment. Your
            answers will be shared with the assessment's organisers."
      >
        <Box mt={4}>
          {reflectionQuestions.map((r, rIdx) => (
            <Box mt={0} mb={0} key={r.id}>
              <Controller
                control={control}
                name={`reflections[${rIdx}].answer`}
                render={({
                  field: { id, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      key={id}
                      id={`answer-${rIdx}`}
                      label={`Question ${getValues(
                        `reflections[${rIdx}].qnNo`,
                      )}: ${getValues(`reflections[${rIdx}].question`)}`}
                      value={value}
                      fullWidth
                      multiline
                      required
                      rows={3}
                      placeholder="Type your response here"
                      error={error}
                      helperText={error && error.message}
                      data-cy={`answer-${rIdx}`}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
          ))}
        </Box>
      </FdCard>
      <BasePageButtonContainer>
        <FdButton
          size="large"
          onClick={() => setModalOpen(true)}
          disabled={submitPostAssessmentReflectionLoading}
        >
          {submitPostAssessmentReflectionLoading ? (
            <FdLoadingSpinner />
          ) : (
            'Submit reflection'
          )}
        </FdButton>
      </BasePageButtonContainer>
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Submit Reflection?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to submit your reflection?
            </FdTypography>
            <Box mt={1}>
              You cannot edit your responses after you submit your reflection.
            </Box>
          </Box>
        }
        confirm="Cancel"
        dismiss={
          submitPostAssessmentReflectionLoading
            ? 'Loading...'
            : 'Submit reflection'
        }
        open={modalOpen}
        onConfirm={() => setModalOpen(false)}
        onDismiss={() => {
          setModalOpen(false);
          // submit questions
          submitPostAssessmentReflectionMutation({
            variables: {
              assessmentId:
                userAssessment?.getUserAssessment?.userAssessmentAssessmentId,
              questionAnswers: reflectionQuestions.map((qn) => ({
                questionId: qn.id,
                answer: qn.answer,
              })),
            },
          });
        }}
        data-cy="finish-assessment-modal"
      />
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (
            window.location.pathname !==
            `/assessor/assessment-post-reflection/${assessmentId}`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="Your reflection has not been submitted. Click “Stay” to go back and submit your reflection."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default AssessmentPostReflection;
