import React from 'react';
import PropTypes from 'prop-types';
import { Link, useTheme } from '@mui/material';
import { FdTooltip } from '@fifthdomain/fe-shared';

const ParticipantCell = ({ onLinkClick, name, teamBased }) => {
  const theme = useTheme();
  return (
    <FdTooltip title={`Go to ${teamBased ? "Team's" : "User's"} Insights`}>
      <Link
        style={{
          paddingLeft: '0.5rem',
          color: theme.palette.typography.primary,
          textDecoration: 'underline',
        }}
        href="/"
        onClick={(e) => {
          e.preventDefault();
          onLinkClick();
        }}
      >
        {name}
      </Link>
    </FdTooltip>
  );
};

ParticipantCell.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  teamBased: PropTypes.bool.isRequired,
};

export default ParticipantCell;
