import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors } from '@mui/material';
import { FdTypography, FdHighlightChip } from '@fifthdomain/fe-shared';
import InsightsTable from '../Assessment/Insights/InsightsTable';
import BlobWithDescription from '../Assessment/Insights/BlobWithDescription';
import ParticipantCell from '../Assessment/Insights/TableColumns/ParticipantCell';
import ColumnHeaderWithTooltip from '../Assessment/Insights/TableColumns/ColumnHeaderWithTooltip';
import HeatMapCell from '../Assessment/Insights/TableColumns/HeatMapCell';
import { formatMinutes } from '../../shared/utils/dateUtils';
import { getAttemptStatusColor } from '../Assessment/Insights/TableColumns/cellColors';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import { getEfficiency } from './overviewUtils';

const TaskCompletionTable = ({
  allTasks,
  participants,
  onParticipantClick,
  teamBased,
  timeSpentData,
  teamGroups,
  allTaskAttempts,
  loading,
}) => {
  const { green, grey, red } = colors;
  const columns = [
    {
      field: 'name',
      width: 250,
      headerName: 'Name',
      renderCell: (params) => (
        <ParticipantCell
          onLinkClick={() => onParticipantClick(params.value)}
          name={params.value}
          teamBased={teamBased}
        />
      ),
    },
    ...allTasks?.map((t) => ({
      field: t.taskId,
      width: 220,
      headerName: t.taskName,
      renderHeader: () => (
        <ColumnHeaderWithTooltip
          title={t?.taskName}
          subTitle={
            <FdHighlightChip text={getDifficultyLabel(t?.taskDifficulty)} />
          }
        />
      ),
      sortComparator: (v1, v2) => v1.value - v2.value,
      renderCell: (params) => (
        <HeatMapCell
          label={params.value?.caption}
          color={params.value?.color}
        />
      ),
    })),
  ];

  const rows = participants?.map((p) => {
    const groupUserIds = teamBased
      ? teamGroups
          ?.find((tg) => tg?.group?.id === p.userId)
          ?.group?.users?.items?.map((u) => u.user?.id)
      : [];
    const userAttempts = teamBased
      ? allTaskAttempts?.filter((ta) => groupUserIds.includes(ta.userId))
      : allTaskAttempts?.filter((ta) => ta.userId === p.userId);
    return {
      id: p.userId,
      name: p.userName,
      ...allTasks?.reduce((acc, t) => {
        // time spent by each participant on a particular task
        // if teamBased the take sum of all minutes
        const timeSpent = teamBased
          ? timeSpentData
              ?.filter(
                (ts) =>
                  groupUserIds.includes(ts.userId) && ts.taskId === t.taskId,
              )
              .reduce((tsAcc, i) => tsAcc + i?.timeSpent, 0)
          : timeSpentData?.find(
              (ts) => ts.userId === p.userId && ts.taskId === t.taskId,
            )?.timeSpent || 0;
        const taskAttempts = userAttempts?.filter(
          (ua) => ua.taskId === t.taskId,
        );
        const efficiency = getEfficiency(
          [p.userId],
          taskAttempts,
          t?.taskPoints,
        );
        acc[t.taskId] = {
          value: timeSpent,
          caption: formatMinutes(timeSpent, true), // hideHoursIfZero is set to true
          color: getAttemptStatusColor(taskAttempts, efficiency),
        };
        return acc;
      }, {}),
    };
  });
  const candidateOrTeamText = teamBased ? 'team' : 'participant';

  return (
    <InsightsTable
      toolbarSettings={{
        tableWidth: '100%',
        searchBox: true,
        title: 'Challenge Completion',
        subTitle: (
          <Box>
            <FdTypography variant="body2" color="secondary">
              {`The heatmap below shows the time spent on each challenge by each ${candidateOrTeamText} and their efficiency in solving that challenge. Each cell will be colour-coded by the Efficiency score of the ${candidateOrTeamText}. Efficiency is the ability to successfully solve a challenge in the least number of attempts. It is influenced by both completion rate and success rate, rewarding ${candidateOrTeamText}s who effectively solve challenges with fewer initiated attempts`}
            </FdTypography>
            <Box display="flex" mt={2}>
              <BlobWithDescription color={grey[400]} label="Not Attempted" />
              <BlobWithDescription
                color={red[300]}
                label="Attempted but not solved"
              />
              <BlobWithDescription
                color={green[50]}
                label="Solved with Efficiency score < 33"
              />
              <BlobWithDescription
                color={green[200]}
                label="Solved with Efficiency score between 33-66"
              />
              <BlobWithDescription
                color={green[400]}
                label="Solved with Efficiency score over 66"
              />
            </Box>
          </Box>
        ),
      }}
      columns={columns}
      rows={rows}
      loading={loading}
    />
  );
};

TaskCompletionTable.propTypes = {
  allTasks: PropTypes.arrayOf(
    PropTypes.shape({
      taskId: PropTypes.string,
      taskName: PropTypes.string,
    }),
  ).isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      userName: PropTypes.string,
    }),
  ).isRequired,
  onParticipantClick: PropTypes.func.isRequired,
  teamBased: PropTypes.bool.isRequired,
  timeSpentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TaskCompletionTable;
